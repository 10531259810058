import dayjs from "dayjs"
import Numeral from "numeral"
import { ref } from "../config/constants"
import { Dictionary } from "lodash"
import firebase from "firebase/compat"
import * as advancedFormat from "dayjs/plugin/advancedFormat"

dayjs.extend(advancedFormat as any)

export enum StyleField {
    title,
    tableHeader,
    leftTableHeader,
    tableValue,
    leftTableValue,
    rightTableValue
}

interface Timing {
    timestamp: number
    timestamp_date_string: string
    timestamp_string: string
    timestamp_week_string: string
    timezone: string
}

export interface Payment {
    amount: number
    identifier: string
    payment_type: string
    success: boolean
    metadata?: Dictionary<any> 
}

interface SaleSource {
    cashier_id: string
    cashier_name: string
    market_id: string
    market_name: string
    register_id: string
    register_name: string
    shop_id: string
    shop_name: string
}

interface SaleSummary {
    base_price: number
    discounts: any //TODO
    vat_amount: number
    included_tax_amount: number //DEPRECATED: now vat_amount
    is_return: boolean
    line_items?: SaleLineItemSummary[]
    sales_tax_amount: number
    sub_total: number
    taxes: any[] //TODO
    total: number
    total_discounts: number
    total_tax_amount: number
}

interface DiscountSummary {
    amount: number
    //NOTE incomplete
}

export interface SaleLineItemSummary {
    barcode?: string
    base_price?: number
    behavior?: any, //TODO
    dimensions?: any[] //TODO
    discounts?:  DiscountSummary[]
    cost_price?: number
    ecom_id?: string
    id?: string
    image_url?: string
    line_item_id: string
    margin?: number
    margin_total?: number
    name?: string
    variant_name?: string
    original_line_item_id?: string
    original_item_index?: number
    quantity: number
    retail_price: number
    sales_tax_amount: number
    sub_total: number
    taxes: any[] //TODO
    tags?: string[]
    total: number
    total_tax_amount: number
    variant_id?: string
    vat_amount: number
    comment?: string
    product_group?: string
    attributes: any
}

export interface Sale {
    base_currency_code: string
    identifier: string
    payments: Payment[]
    receipt_metadata: any //TODO
    sequence_number: number
    source: SaleSource
    summary: SaleSummary
    timing: Timing
    voided: boolean
}

export function formatTime(time: number): string {
    return dayjs.unix(time).format("MMMM Do YYYY HH.mm") //TODO: consider locale instead
}

export function formatTwoDigits(number: number) {
    return Numeral(number).format("0,0.00")
}

export function dataFormatCurrencyAmount(number: number, decimalSeparator: string) {
    const formatted = Numeral(number).format("0.00")
    if (decimalSeparator === ".") {
        return formatted
    } else {
        return formatted.replace(".", decimalSeparator)
    }
}

export class BaseReport {
    async buildDocumentName(account: string, shop: string, isFileName: boolean, reportName: string): Promise<string> {
        const accountNameSnapshot = await ref().child(`v1/account_index/${account}/name`).once("value")
        const accountName = accountNameSnapshot.exists() ? accountNameSnapshot.val() : undefined

        const shopNameSnapshot = await ref().child(`v1/accounts/${account}/shop_index/${shop}/name`).once("value")
        const shopName = shopNameSnapshot.exists() ? shopNameSnapshot.val() : undefined

        const now = dayjs().format("MMMM Do YYYY, H:mm:ss")
        if (isFileName) {
            dayjs().format("MMMM Do YYYY, H_mm_ss")
        }
        let result = ""
        if (accountName) {
            result += accountName
            if (isFileName) {
                result += " - "
            } else {
                result += ": "
            }
        }
        result += `${reportName} for `
        if (shopName) {
            result += shopName
            result += ", "
        }
        result += now
        return result
    }
}

export interface ReportLine {
    forCSV(decimalSeparator: string): any[]
    forPDF(): object[]
}

export interface ReportType extends BaseReport {
    title(): string
    description(): string
    prepare(accountRef: firebase.database.Reference): Promise<void>
    headers(): string[]
    buildLines(salesSnapshot: firebase.database.DataSnapshot): ReportLine[]
}
