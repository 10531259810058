import * as React from "react"
import {
    Button,
    Modal,
} from "../../wrappers"
import { Role } from "../../../config/role"
import { FrontPageSection, MostSoldProductsSection, ProductsSection, ShortcutsSection } from "./FrontPageSectionModels"
import { LanguagePicker } from "../../LanguagePicker"
import { LanguageCode } from "../../../helpers/L10n"
import _ from "lodash"
import { ShortcutsSectionEdit } from "./ShortcutsSectionEdit"
import { ProductsSectionEdit } from "./ProductsSectionEdit"
import { MostSoldProductsSectionEdit } from "./MostSoldProductsSectionEdit"

interface FrontPageSectionEditProps {
    role: Role
    section: FrontPageSection
    done(section?: FrontPageSection): void
}

interface FrontPageSectionEditState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    section: FrontPageSection
    currentLanguage: LanguageCode | null
}

export class FrontPageSectionEdit extends React.Component<FrontPageSectionEditProps, FrontPageSectionEditState> {

    constructor(props: FrontPageSectionEditProps) {
        super(props)
        this.state = {
            dirty: false,
            loaded: true,
            publishing: false,
            section: props.section,
            currentLanguage: null
        }
    }

    // Component

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    resolveLanguages = (section: FrontPageSection | null): LanguageCode[] => {
        if (section === null) { return [] }
        const localizations = new Set<LanguageCode>()

        const l10n = section.mostSoldProducts?.title ?? section.productsSection?.title ?? section.shortcutsSection?.title
        if (l10n) {
            l10n.localizations().forEach(language => {
                localizations.add(language)
            })
        }
        return Array.from(localizations).sort()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })

        if (_.isNil(language)) { return }
        const languages = this.resolveLanguages(this.state.section)
        if (!languages.includes(language)) {
            const section = _.cloneDeep(this.state.section)
            const l10n = section.mostSoldProducts?.title ?? section.productsSection?.title ?? section.shortcutsSection?.title
            if (l10n) {
                l10n.localizeTo(language)
            }
            this.setState({ section: section, dirty: true })
        }
    }

    removeLanguage = (language: LanguageCode) => {
        const section = _.cloneDeep(this.state.section)
        const l10n = section.mostSoldProducts?.title ?? section.productsSection?.title ?? section.shortcutsSection?.title
        if (l10n) {
            l10n.removeLocalization(language)
        }
        this.setState({ section: section, dirty: true })
    }

    updateMostSoldProductsSection = (subSection: MostSoldProductsSection) => {
        const section = _.cloneDeep(this.state.section)
        section.mostSoldProducts = subSection
        this.setState({ section: section, dirty: true })
    }

    updateProductsSection = (subSection: ProductsSection) => {
        const section = _.cloneDeep(this.state.section)
        section.productsSection = subSection
        this.setState({ section: section, dirty: true })
    }

    updateShortcutsSection = (subSection: ShortcutsSection) => {
        const section = _.cloneDeep(this.state.section)
        section.shortcutsSection = subSection
        this.setState({ section: section, dirty: true })
    }

    render() {
        return (
            <Modal show={true} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit section</span>
                        <span style={{ float: "right" }}>
                            <LanguagePicker
                                typeName="section"
                                initialLanguage={this.state.currentLanguage}
                                resolveLanguages={() => { return this.resolveLanguages(this.state.section) }}
                                onChange={this.setLanguage}
                                onRemove={this.removeLanguage}
                            />
                        </span>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.state.section.type === "shortcuts" ?
                        <ShortcutsSectionEdit section={this.state.section.shortcutsSection!} updateSection={this.updateShortcutsSection} currentLanguage={this.state.currentLanguage} /> :
                        (this.state.section.type === "curated_products" ? <ProductsSectionEdit role={this.props.role} section={this.state.section.productsSection!} updateSection={this.updateProductsSection} currentLanguage={this.state.currentLanguage} /> :
                            (this.state.section.type === "most_sold_products" ? <MostSoldProductsSectionEdit updateSection={this.updateMostSoldProductsSection} section={this.state.section.mostSoldProducts!} currentLanguage={this.state.currentLanguage} /> : null))}
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.props.done()}>Cancel</Button>
                    <Button onClick={() => this.props.done(this.state.section)} disabled={!this.state.dirty} >Done</Button>
                </Modal.Footer>

            </Modal>
        )
    }
}
