//
//  MarketModels.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 03/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

export interface Channel {
    id: string
    name: string
}

export interface Market {
    id: string
    name: string
    currency: string
    taxes: Set<string>
}

export enum TaxType {
    vat,
    salesTax
}

export function taxTypeName(taxType: TaxType): string {
    switch (taxType) {
        case TaxType.vat:
            return "vat"
        case TaxType.salesTax:
            return "sales_tax"
    }
}

export function taxTypeFor(name: string): TaxType {
    switch (name) {
        case "sales_tax":
            return TaxType.salesTax
        default:
            return TaxType.vat
    }
}

export class Tax {
    id: string
    rate: number
    name: string
    type: TaxType
    lastUpdated?: number
    created?: number

    constructor(json: any) {
        this.name = json.name
        this.id = json.id
        this.rate = json.rate
        this.type = json.type ? taxTypeFor(json.type) : TaxType.vat
        if (json.last_updated) {
            this.lastUpdated = json.last_updated
        }
        if (json.created) {
            this.created = json.created
        }
    }

    json(): any {
        return {
            name: this.name,
            id: this.id,
            rate: this.rate,
            type: taxTypeName(this.type),
            last_updated: this.lastUpdated,
            created: this.created
        }
    }
}

export interface Currency {
    currencyCode: string
}