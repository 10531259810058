import React, { useEffect, useState } from "react";
import { RoleRouterProps, withRoleFunc, withRoleRouterFunc } from "src/routes";
import firebase from "firebase/compat/app"
import { StripedTable } from "../../StripedTable";
import { GiftcardType } from "src/models/GiftcardType";
import { ref } from "src/config/constants";
import _ from "lodash";
import { AssetType } from "../GiftcardAssetDropzone";
import { PageState } from "../../PageState";
import { Asset } from "./Asset";
import { PDFTemplate } from "../../../models/pdf_template/pdf_template";
export interface GiftcardConfig {
    baseURL: string
    token: string
    uid: string
}

async function fetchTemplatePromise(accountId: string, type: string): Promise<PDFTemplate> {
    const args: any = {
        action: "giftcard-template-read",
        account: accountId,
        type: type,
        template: "pdf"
    };

    const client = firebase.functions().httpsCallable("clientApi")
    const response = await client(args)
    const pdfTemplate = PDFTemplate.fromJSON(response.data)
    return pdfTemplate
}

function GiftcardTypeList(props: RoleRouterProps) {
    const [giftcardTypes, setGiftcardTypes] = useState<GiftcardType[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)
    const [giftcardConfig, setGiftcardConfig] = useState<GiftcardConfig>()
    const [fontList, setFontList] = useState<string[]>([])
    const [imageList, setImageList] = useState<string[]>([])
    const [pdfTemplatesLoaded, setPDFTemplatesLoaded] = useState<boolean>(false)
    const [imagesInUse, setImagesInUse] = useState<Set<string>>(new Set())
    const [fontsInUse, setFontsInUse] = useState<Set<string>>(new Set())
    const accountId = props.role.account_id
    useEffect(() => {
        (async () => {
            const types = await getGiftcardTypes(accountId)
            const giftcardConfig = await getGiftcardConfig(accountId)
            setGiftcardTypes(types)
            setGiftcardConfig(giftcardConfig)
            if (!_.isNil(giftcardConfig)) {
                const fonts = await getAssetList("font", giftcardConfig)
                const images = await getAssetList("image", giftcardConfig)
                setFontList(fonts)
                setImageList(images)
            }
            setLoaded(true)

            const _imagesInUse: Set<string> = new Set()
            const _fontsInUse: Set<string> = new Set()
            for (const type of types) {
                try {
                    const template = await fetchTemplatePromise(accountId, type.id)
                    for (const line of template.lines) {
                        if (!_.isNil(line.image)) {
                            _imagesInUse.add(line.image)
                        }
                    } 
                    for (const [name, font] of template.fontMap.entries()) {
                        _fontsInUse.add(font)
                    }
                } catch {
                    // Giftcard type may not have a PDF template
                }
            }
            setFontsInUse(_fontsInUse)
            setImagesInUse(_imagesInUse)
            setPDFTemplatesLoaded(true)
        })();
    }, [props.role.account_id])
    return (
        <PageState loading={!loaded} typeName="">
            <div>
                <h1>Giftcard types </h1>
                <StripedTable>
                    <thead>
                        <tr>
                            <th style={{ fontWeight: "bold" }}>Identifier</th>
                            <th style={{ fontWeight: "bold" }}>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {giftcardTypes.map((type: GiftcardType) => {
                            return (
                                <tr key={type.id} onClick={(() => {
                                    const path = `${type.id}`
                                    props.router.navigate(path)
                                })}>
                                    <td> { type.id }</td>
                                    <td> { type.name }</td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </StripedTable>
                { !_.isNil(giftcardConfig) ? 
                <Asset 
                    giftcardConfig={giftcardConfig} 
                    fonts={fontList} 
                    images={imageList} 
                    updatedFonts={(fonts) => setFontList(fonts)} 
                    updatedImages={(images) => setImageList(images)}
                    addedFont={(fontName) => {
                        setFontList(prevFontList => {
                            return addAsset(prevFontList, fontName);
                        });
                    }}
                    addedImage={(image) => {
                        setImageList(prevImageList => {
                            return addAsset(prevImageList, image)
                        });
                    }}
                    imagesInUse={imagesInUse}
                    fontsInUse={fontsInUse}
                    ready={pdfTemplatesLoaded}
                /> 
                : null }
            </div>
        </PageState>
    );
}

function addAsset(prevAssets: string[], assetToAdd: string) {
    const updatedAssets = [...prevAssets];
    if (!updatedAssets.includes(assetToAdd)) {
        updatedAssets.push(assetToAdd);
    }
    return updatedAssets;
}

async function getAssetList(assetType: AssetType, giftcardConfig: GiftcardConfig): Promise<string[]> {
    const assetPath = assetType == "image" ? `/api/v1/asset/image-list` : `/api/v1/asset/font-list`
    const url = giftcardConfig.baseURL + assetPath

    const headers = new Headers()

    headers.append("token", giftcardConfig.token)
    headers.append("uid", giftcardConfig.uid)

    const requestInit: RequestInit = {
        method: "GET",
        headers: headers,
    }

    const request = new Request(url, requestInit)
    const response = await fetch(request)
    const json = await response.json()

    if(!_.isNil(json.file_names)) {
        const names = json.file_names as string[]
        return names
    } else {
        return []
    }
}

async function getGiftcardTypes(accountId: string): Promise<GiftcardType[]> {
    const args: any = {
        action: "giftcard-type-list",
        account: accountId
    };

    const client = firebase.functions().httpsCallable("clientApi");
    const value = await client(args);

    const data = (value.data as any[])
    const giftcardTypes: GiftcardType[] = data.flatMap((type: any) => {
        const value = GiftcardType.fromJSON(type)
        return value ? [value] : []
    })
    return giftcardTypes
}

async function getGiftcardConfig(accountId: string): Promise<GiftcardConfig | undefined> {
    const snap = await ref().child(`v1/accounts/${accountId}/configuration/giftcard_service_config`).get()
    if (!snap.exists()) { return undefined }
    const config = snap.val()
    if (!_.isNil(config.token) && !_.isNil(config.uid) && !_.isNil(config.base_url)){
        return {
            token: config.token,
            uid: config.uid,
            baseURL: config.base_url
        }
    }
    return undefined
}

export default withRoleRouterFunc(GiftcardTypeList)

