//
//  RuleModels.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 05/06/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import { Dictionary, isNil, isArray, isNumber } from "lodash"
import { L10nString } from "../helpers/L10n"
import { MarketAmount } from "./MarketAmount"
import _ from "lodash"

export enum TemplateType {
    NewPriceCountOrMoreSingle = "new_price_discount-count_or_more-single_product",
    NewPriceDiscountStairSingle = "new_price_discount-stair-single_product",
    NewPriceDiscountStairMultiple = "new_price_discount-stair-multiple_products",
    NewPriceSingle = "new_price_discount-single_product",
    NewPriceMultiple = "new_price_discount-multiple_products",
    PercentageCountOrMoreMultiple = "percentage_discount-count_or_more-multiple_products",
    PercentageCountOrMoreSingle = "percentage_discount-count_or_more-single_product",
    PercentageCountOrMoreTag = "percentage_discount-count_or_more-tag",
    PercentageTag = "percentage_discount-tag",
    PercentageDiscountStairSingle = "percentage_discount-stair-single_product",
    PercentageDiscountStairTag = "percentage_discount-stair-tag",
    AmountDiscountStairTag = "amount_discount-stair-tag",
    ShippingByAmount = "free_shipping_by_amount",
    PercentageDiscountBasketTotalStairTag = "percentage_discount-basket_total_stair-tag",
    BuyXGetY = "buy_x_get_y",
    Bundle = "bundle",
    // Shipping = "shipping",
    Customizable = "customizable",
    LoyaltyPoints = "loyalty_points"
}

export type FormType = "discount" | "loyalty_points"
export interface CouponTemplate {
    identifier: string
    name: string
}

export type Expiration = RelativeExpiration | FixedExpiration
export interface FixedExpiration {
    start_date: DateComponents
    end_date: DateComponents
}
export interface RelativeExpiration {
    count?: number
    date_component?: DateComponent
}

export function isFixedExpiration(expiration: Expiration): expiration is FixedExpiration {
    return 'start_date' in expiration && 'end_date' in expiration;
}

export function isRelativeExpiration(expiration: Expiration): expiration is RelativeExpiration {
    return 'count' in expiration && 'date_component' in expiration;
}

export type DateComponent = "day" | "month" | "year"

export class CouponValues {
    template?: CouponTemplate
    title?: L10nString
    subtitle?: L10nString
    expiration?: Expiration

    constructor(
        title: L10nString | undefined = undefined, 
        subtitle: L10nString | undefined = undefined, 
        template: CouponTemplate | undefined = undefined,
        expiration: Expiration | undefined = undefined
        ) {
        this.title = title
        this.subtitle = subtitle
        this.template = template
        this.expiration = expiration
    }

    toJSON(): any {
        let json: any = this;
        json.title = this.title?.json() ?? new L10nString("-")
        if(!_.isNil(json.subtitle)){
            json.subtitle = this.subtitle?.json() ?? null
        } else {
            delete json.subtitle
        }
        return json
    }

    static fromJSON(couponJSON: any): CouponValues | undefined{
        if (!_.isNil(couponJSON)) {
            let coupon = new CouponValues(couponJSON.title, couponJSON.subtitle, couponJSON.template, couponJSON.expiration)
            if (!_.isNil(coupon.title)) {
                coupon!.title = new L10nString(couponJSON.title);
            }
            if (!_.isNil(coupon.subtitle)) {
                coupon!.subtitle = new L10nString(couponJSON.subtitle);
            }
            return coupon
        }
        return undefined
    }
}

export const availableTemplateTypes: TemplateType[] = [
    TemplateType.Customizable,
    TemplateType.Bundle,
    TemplateType.BuyXGetY,
    TemplateType.LoyaltyPoints
    // TemplateType.Shipping
]

export const couponRules: TemplateType[] = [
    TemplateType.Customizable,
    TemplateType.Bundle,
    TemplateType.BuyXGetY
]

export const legacyTemplateTypes: TemplateType[] = [
    TemplateType.PercentageCountOrMoreSingle,
    TemplateType.PercentageCountOrMoreMultiple,
    TemplateType.PercentageCountOrMoreTag,
    TemplateType.NewPriceSingle,
    TemplateType.NewPriceMultiple,
    TemplateType.NewPriceCountOrMoreSingle,
    TemplateType.NewPriceDiscountStairSingle,
    TemplateType.NewPriceDiscountStairMultiple,
    TemplateType.PercentageTag,
    TemplateType.PercentageDiscountStairSingle,
    TemplateType.PercentageDiscountStairTag,
    TemplateType.AmountDiscountStairTag,
    TemplateType.ShippingByAmount,
    TemplateType.PercentageDiscountBasketTotalStairTag
]

export const allTemplateTypes: TemplateType[] = availableTemplateTypes.concat(legacyTemplateTypes)

const customPropertiesForTemplateType: Dictionary<RuleProperty[]> = {
    "percentage_discount-count_or_more-single_product": ["percentage", "count", "product_id"],
    "percentage_discount-count_or_more-multiple_products": ["percentage", "count", "product_ids"],
    "percentage_discount-tag": ["percentage", "tag"],
    "new_price_discount-multiple_products": ["new_price", "only_if_cheaper", "product_ids"],
    "new_price_discount-single_product": ["new_price", "only_if_cheaper", "product_id"],
    "new_price_discount-count_or_more-single_product": ["new_price", "only_if_cheaper", "product_id", "count"],
    "new_price_discount-stair-single_product": ["new_price_stair", "only_if_cheaper", "product_id"],
    "new_price_discount-stair-multiple_products": ["new_price_stair", "only_if_cheaper", "product_ids"],
    "percentage_discount-count_or_more-tag": ["percentage", "count", "tag"],
    "percentage_discount-stair-single_product": ["percentage_stair", "product_id"],
    "percentage_discount-stair-tag": ["percentage_stair", "tag"], // ???
    "amount_discount-stair-tag": ["amount_stair", "tag"],
    "free_shipping_by_amount": ["amount_condition"],
    "percentage_discount-basket_total_stair-tag": ["percentage_basket_total_stair", "tag"],
}

export const typeToNameMap: Dictionary<string> = {
    "percentage_discount-count_or_more-single_product": "Percentage discount for a specified number or more of a specific product",
    "percentage_discount-count_or_more-multiple_products": "Percentage discount for a specified number or more from a set of multiple products",
    "percentage_discount-tag": "Percentage discount for all products matching a specific tag",
    "new_price_discount-single_product": "New price discount for a specific product",
    "new_price_discount-multiple_products": "New price discount for a set of multiple products",
    "new_price_discount-count_or_more-single_product": "New price discount for a specified number or more of a specific product",
    "new_price_discount-stair-single_product": "Price stair for a specific product",
    "new_price_discount-stair-multiple_products": "Price stair for a set of multiple products",
    "percentage_discount-count_or_more-tag": "Percentage discount for a specified number or more of products matching a specific tag",
    "percentage_discount-stair-single_product": "Percentage discount stair for a specific product",
    "percentage_discount-stair-tag": "Percentage discount stair for all products matching a specific tag",
    "amount_discount-stair-tag": "Amount off stair for all products matching a specific tag",
    "free_shipping_by_amount": "Free shipping based on total amount condition",
    "percentage_discount-basket_total_stair-tag": "Percentage off for products matching a specific tag based on basket total stair",
    "customizable": "Customizable",
    "bundle": "Bundle",
    "buy_x_get_y": "Buy X get Y",
    "shipping": "Shipping",
    "loyalty_points": "Loyalty Points"
}

export function useLegacyEditor(template: string): boolean {
    if (template === "customizable") { return false }
    if (template === "bundle") { return false }
    if (template === "buy_x_get_y") { return false }
    if (template === "shipping") { return false }
    if (template === "loyalty_points") { return false }
    return true
}

export const typeToNameMapShort: Dictionary<string> = {
    "percentage_discount-count_or_more-single_product": "Percentage discount by count of product",
    "percentage_discount-count_or_more-multiple_products": "Percentage discount by count of multiple products",
    "percentage_discount-tag": "Percentage discount by tag",
    "new_price_discount-single_product": "New price, specific product",
    "new_price_discount-multiple_products": "New price discount for a set of multiple products",
    "new_price_discount-count_or_more-single_product": "New price by count of product",
    "new_price_discount-stair-single_product": "Price stair for a product",
    "new_price_discount-stair-multiple_products": "Price stair for a set of multiple products",
    "percentage_discount-stair-single_product": "Percentage discount stair for a product",
    "percentage_discount-stair-tag": "Percentage discount stair by tag",
    "percentage_discount-count_or_more-tag": "Percentage discount by count matching tag",
    "amount_discount-stair-tag": "Amount off discount stair by tag",
    "free_shipping_by_amount": "Free shipping based on total amount condition",
    "percentage_discount-basket_total_stair-tag": "Percentage off by tag from basket total stair",
    "customizable": "Customizable",
    "bundle": "Bundle",
    "buy_x_get_y": "Buy X get Y",
    "shipping": "Shipping",
    "loyalty_points": "Loyalty Points"
}

export const typeToRequiresContinueEvaluation: Dictionary<boolean> = {
    "percentage_discount-basket_total_stair-tag": true,
}

export function showShowBasketScopeCheckbox(type: TemplateType): boolean {
    switch (type) {
        case TemplateType.AmountDiscountStairTag:
            return true

        default:
            return false
    }
}

export class Metadata {
    channels: Dictionary<boolean>
    markets: Dictionary<boolean>

    constructor(channels: Dictionary<boolean>, markets: Dictionary<boolean>) {
        this.channels = channels
        this.markets = markets
    }
}

export interface DateComponents {
    year: number,
    month: number,
    day: number
}

export class NewPriceStairStep {
    new_price?: MarketAmount
    count?: number

    constructor(object?: Dictionary<any>) {
        if (!object) {
            return
        }
        if (!isNil(object["new_price_per_item_if_cheaper"])) {
            this.new_price = new MarketAmount(object["new_price_per_item_if_cheaper"])
        } else if (!isNil(object["new_price_per_item"])) {
            this.new_price = new MarketAmount(object["new_price_per_item"])
        }
        this.count = object["count"]
    }

    json(onlyIfCheaper: boolean): any {
        const val = { count: this.count }
        if (!isNil(this.new_price)) {
            if (onlyIfCheaper) {
                val["new_price_per_item_if_cheaper"] = this.new_price.json()
            } else {
                val["new_price_per_item"] = this.new_price.json()
            }
        }
        return val
    }
}

export class AmountStairStep {
    amount?: MarketAmount
    count?: number

    constructor(object?: Dictionary<any>) {
        if (!object) {
            return
        }
        if (!isNil(object["amount_per_item"])) {
            this.amount = new MarketAmount(object["amount_per_item"])
        }
        this.count = object["count"]
    }

    json(): any {
        const val = { count: this.count }
        if (!isNil(this.amount)) {
            val["amount_per_item"] = this.amount.json()
        }
        return val
    }
}

export class PercentageStairStep {
    percentage?: number
    count?: number

    constructor(object?: Dictionary<any>) {
        if (!object) {
            return
        }
        this.percentage = object["percentage"]
        this.count = object["count"]
    }

    json(): any {
        const val = { count: this.count, percentage: this.percentage }
        return val
    }
}

export class PercentageBasketTotalStairStep {
    percentage?: number
    amount?: MarketAmount

    constructor(object?: Dictionary<any>) {
        if (!object) {
            return
        }
        this.percentage = object["percentage"]
        this.amount = new MarketAmount(object["amount"])
    }

    json(): any {
        const val = { percentage: this.percentage }
        if (!isNil(this.amount)) {
            val["amount"] = this.amount.json()
        }
        return val
    }
}

export type RuleScope = "line_items" | "basket"

export type RuleProperty = "continue_evaluation" | "name" | "display_name" | "priority" | "members_only" | "start_date" | "end_date" | "count" | "percentage" | "new_price" | "new_price_stair" | "percentage_stair" | "amount_stair" | "only_if_cheaper" | "product_id" | "tag" | "product_ids" | "amount_condition" | "scope" | "percentage_basket_total_stair"

export interface RuleModel {
    setMarkets: (allMarkets: string[]) => void
    display_name?: L10nString
    json: () => any
    is_coupon?: boolean
    valid: (selectedMarkets: string[]) => boolean
}

export class LegacyRuleModel {
    // Shared properties
    name?: string
    display_name?: L10nString
    priority?: number
    members_only?: boolean
    continue_evaluation?: boolean
    start_date?: DateComponents
    end_date?: DateComponents
    scope?: RuleScope

    // 'custom' properties
    amount_condition?: MarketAmount
    count?: number
    percentage?: number
    new_price?: MarketAmount
    only_if_cheaper?: boolean
    product_id?: string
    product_ids?: string[]
    tag?: string
    new_price_stair?: NewPriceStairStep[]
    percentage_stair?: PercentageStairStep[]
    amount_stair?: AmountStairStep[]
    percentage_basket_total_stair?: PercentageBasketTotalStairStep[]

    templateType: TemplateType
    sharedProperties: RuleProperty[] = ["name", "display_name", "priority", "members_only", "start_date", "end_date", "continue_evaluation", "scope"]
    customProperties: RuleProperty[]

    constructor(type: TemplateType, object?: Dictionary<any>) {
        this.customProperties = customPropertiesForTemplateType[type]
        this.templateType = type
        if (typeToRequiresContinueEvaluation[type] === true) {
            this.continue_evaluation = true
        }

        if (!object) {
            return
        }

        for (const property of this.sharedProperties.concat(this.customProperties)) {
            switch (property) {
                // 'plain value' properties go here
                // case "name":
                case "priority":
                case "start_date":
                case "end_date":
                case "percentage":
                case "product_ids":
                case "count":
                case "scope":
                    if (!isNil(object[property])) {
                        this[property] = object[property]
                    }
                    break

                case "amount_condition":
                    if (!isNil(object[property])) {
                        this[property] = new MarketAmount(object[property])
                    }
                    break

                case "product_id":
                case "name":
                case "tag":
                    if (!isNil(object[property])) {
                        this[property] = object[property]
                    }
                    break

                // All 'L10nString' properties go here
                case "display_name": {
                    if (!isNil(object[property])) {
                        this[property] = new L10nString(object[property])
                    }
                    break
                }

                // Other special cases:
                case "new_price": {
                    let actualProperty = "new_price_per_item"
                    const ifCheaper = !isNil(object["new_price_per_item_if_cheaper"])
                    this["only_if_cheaper"] = ifCheaper
                    if (ifCheaper) {
                        actualProperty = "new_price_per_item_if_cheaper"
                    }
                    if (!isNil(object[actualProperty])) {
                        this[property] = new MarketAmount(object[actualProperty])
                    }
                    break
                }

                case "continue_evaluation":
                case "members_only": {
                    // only add this if it is true. No reason to carry around the 'false' value
                    if (object[property] === true) {
                        this[property] = true
                    }
                    break
                }

                case "new_price_stair": {
                    const value = object["steps"]
                    if (!isNil(value) && isArray(value) && value.length > 0) {
                        const first = value[0]
                        this["only_if_cheaper"] = !isNil(first["new_price_per_item_if_cheaper"])
                        this[property] = value.map(json => { return new NewPriceStairStep(json) })
                    }
                    break
                }

                case "amount_stair": {
                    const value = object["steps"]
                    if (!isNil(value) && isArray(value) && value.length > 0) {
                        this[property] = value.map(json => { return new AmountStairStep(json) })
                    }
                    break
                }

                case "percentage_stair": {
                    const value = object["steps"]
                    if (!isNil(value) && isArray(value)) {
                        this[property] = value.map(json => { return new PercentageStairStep(json) })
                    }
                    break
                }
                case "percentage_basket_total_stair": {
                    const value = object["steps"]
                    if (!isNil(value) && isArray(value)) {
                        this[property] = value.map(json => { return new PercentageBasketTotalStairStep(json) })
                    }
                    break
                }
            }
        }
    }

    json(): any {
        const val = {}
        for (const property of this.sharedProperties.concat(this.customProperties)) {
            switch (property) {
                // 'plain value' properties go here
                case "name":
                case "priority":
                case "percentage":
                case "start_date":
                case "end_date":
                case "tag":
                case "count":
                case "product_id":
                case "product_ids":
                case "scope":
                    if (!isNil(this[property])) {
                        val[property] = this[property]
                    }
                    break

                // All 'json' properties go here
                case "amount_condition":
                case "display_name": {
                    const value = this[property]
                    if (!isNil(value)) {
                        val[property] = value.json()
                    }
                    break
                }

                case "new_price": {
                    const value = this[property]
                    if (!isNil(value)) {
                        const ifCheaper = this.only_if_cheaper === true
                        if (ifCheaper) {
                            val["new_price_per_item_if_cheaper"] = value.json()
                        } else {
                            val["new_price_per_item"] = value.json()
                        }
                    }
                    break
                }

                // Other special cases:
                case "continue_evaluation":
                case "members_only":
                    // only add this if it is true. No reason to carry around the 'false' value
                    if (this[property] === true) {
                        val[property] = true
                    }
                    break

                case "percentage_stair": {
                    const value = this[property] || []
                    val["steps"] = value.map(step => { return step.json() })
                    break
                }

                case "new_price_stair": {
                    const value = this[property] || []
                    val["steps"] = value.map(step => { return step.json((this.only_if_cheaper === true)) })
                    break
                }

                case "amount_stair": {
                    const value = this[property] || []
                    val["steps"] = value.map(step => { return step.json() })
                    break
                }

                case "percentage_basket_total_stair": {
                    const value = this[property] || []
                    val["steps"] = value.map(step => { return step.json() })
                    break
                }
            }
        }
        return val
    }

    valid(selectedMarkets: string[]): boolean {
        const fields = this.sharedProperties.concat(this.customProperties)
        for (const field of fields) {
            if (!this.validateField(field, selectedMarkets)) {
                return false
            }
        }
        return true
    }

    validateField(fieldName: RuleProperty, selectedMarkets?: string[]): boolean {
        switch (fieldName) {
            case "name":
                return (this.name && this.name.length > 0) || false

            case "display_name":
                if (!this.display_name) {
                    return false
                }
                return !this.display_name.hasEmptyLocalizations()

            case "amount_condition":
                return validateMarketAmount(this.amount_condition, selectedMarkets)

            case "priority":
                return validateNonZeroInteger(this.priority)

            case "product_id":
                return typeof this.product_id === "string" && this.product_id.length > 0

            case "product_ids":
                return typeof this.product_ids === "object" && this.product_ids.length > 0

            case "new_price":
                return validateMarketAmount(this.new_price, selectedMarkets)

            case "count":
                return validateNonZeroInteger(this.count)

            case "tag":
                return typeof this.tag === "string"

            case "percentage":
                return validateNonZeroNumber(this.percentage, 1)

            case "start_date":
                return true

            case "end_date":
                return true

            case "continue_evaluation":
            case "members_only":
                return true

            case "only_if_cheaper":
                return true

            case "percentage_stair": {
                if (isNil(this.percentage_stair) || this.percentage_stair.length === 0) {
                    return false
                }
                for (const step of this.percentage_stair) {
                    if (!validateNonZeroInteger(step.count)) {
                        return false
                    }
                    if (!validateNonZeroNumber(step.percentage, 1)) {
                        return false
                    }
                }
                return true
            }

            case "percentage_basket_total_stair": {
                if (isNil(this.percentage_basket_total_stair) || this.percentage_basket_total_stair.length === 0) {
                    return false
                }
                for (const step of this.percentage_basket_total_stair) {
                    if (isNil(step.amount) || !validateMarketAmount(step.amount, selectedMarkets)) {
                        return false
                    }
                    if (!validateNonZeroNumber(step.percentage, 1)) {
                        return false
                    }
                }
                return true
            }

            case "new_price_stair": {
                if (isNil(this.new_price_stair) || this.new_price_stair.length === 0) {
                    return false
                }
                for (const step of this.new_price_stair) {
                    if (!validateNonZeroInteger(step.count)) {
                        return false
                    }
                    if (!validateMarketAmount(step.new_price, selectedMarkets)) {
                        return false
                    }
                }
                return true
            }

            case "amount_stair": {
                if (isNil(this.amount_stair) || this.amount_stair.length === 0) {
                    return false
                }
                for (const step of this.amount_stair) {
                    if (!validateNonZeroInteger(step.count)) {
                        return false
                    }
                    if (!validateMarketAmount(step.amount, selectedMarkets)) {
                        return false
                    }
                }
                return true
            }

            case "scope": {
                if (isNil(this.scope)) {
                    return true
                }
                if (typeof this.scope !== "string") {
                    return false
                }
                if (this.scope !== "line_items" && this.scope !== "basket") {
                    return false
                }
                return true
            }
        }
    }

    containsField(fieldName: RuleProperty): boolean {
        const fields = this.sharedProperties.concat(this.customProperties)
        return fields.includes(fieldName)
    }

    valueForField(fieldName: RuleProperty): any {
        return this[fieldName]
    }

    setMarkets(allMarkets: string[]) {
        if (this.new_price) {
            this.new_price.setMarkets(allMarkets)
        }
        if (this.new_price_stair) {
            for (const step of this.new_price_stair) {
                if (!isNil(step.new_price)) {
                    step.new_price.setMarkets(allMarkets)
                }
            }
        }
        if (this.amount_stair) {
            for (const step of this.amount_stair) {
                if (!isNil(step.amount)) {
                    step.amount.setMarkets(allMarkets)
                }
            }
        }
        if (this.percentage_basket_total_stair) {
            for (const step of this.percentage_basket_total_stair) {
                if (!isNil(step.amount)) {
                    step.amount.setMarkets(allMarkets)
                }
            }
        }
        if (this.amount_condition) {
            this.amount_condition.setMarkets(allMarkets)
        }
    }

    removeMarket(marketKey: string, remaining: string[]) {
        if (this.new_price) {
            this.new_price.removeMarket(marketKey, remaining)
        }
        if (this.amount_condition) {
            this.amount_condition.removeMarket(marketKey, remaining)
        }
        if (this.new_price_stair) {
            for (const step of this.new_price_stair) {
                if (!isNil(step.new_price)) {
                    step.new_price.removeMarket(marketKey, remaining)
                }
            }
        }
        if (this.amount_stair) {
            for (const step of this.amount_stair) {
                if (!isNil(step.amount)) {
                    step.amount.removeMarket(marketKey, remaining)
                }
            }
        }
        if (this.percentage_basket_total_stair) {
            for (const step of this.percentage_basket_total_stair) {
                if (!isNil(step.amount)) {
                    step.amount.removeMarket(marketKey, remaining)
                }
            }
        }
    }
}

function validateMarketAmount(amount?: MarketAmount, markets?: string[]) {
    if (isNil(amount)) { return false }
    if (isNil(markets)) { return false }
    if (markets.length === 0) { return false }
    for (const market of markets) {
        if (!amount.hasAmountFor(market) && !isNumber(amount.value)) {
            return false
        }
    }
    return true
}

function validateNonZeroInteger(n: number | undefined): boolean {
    if (n === undefined) {
        return false
    }
    return (validateNonZeroNumber(n) && Number.isInteger(n))
}

function validateNonZeroNumber(n: number | undefined, limit?: number): boolean {
    if (n === undefined) {
        return false
    }
    let result = n > 0
    if (limit) {
        result = result && n <= limit
    }
    return result
}