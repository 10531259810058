import CopyToClipboard from "react-copy-to-clipboard"
import * as React from "react"
import {
    Alert,
    Button,
    Col,
    FormControl,
    FormGroup,
    Grid,
    Row
} from "../../wrappers"
import { getExistingApiKey } from "../../../helpers/existingApiKey"
import { ref } from "../../../config/constants"
import { v4 as uuid } from "uuid"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Clipboard2Check } from "react-bootstrap-icons"
import { InputGroup } from "react-bootstrap"
interface RecommendationModuleState {
    copied: boolean
}

interface EndPoint {
    url: string
    name: string
}

export class RecommendationModule extends React.Component<ModuleProps, RecommendationModuleState> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
            copied: false,
        }
    }

    recommendationsConfigured(): boolean {
        const config = this.props.moduleData
        if (!config) {
            return false
        }
        if (config.api_key) {
            return true
        }
        return false
    }

    recommendationImportURLs(): EndPoint[] {
        const config = this.props.moduleData
        if (!config) {
            return []
        }
        if (config.api_key) {
            const base = `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/imports/recommendations/?apikey=${config.api_key}&integration=raptor&account=${this.props.role.account_id}`
            const urls = [{ url: `${base}&recommendation_id=similar`, name: "Webhook URL (Similar)" }, { url: `${base}&recommendation_id=upsale`, name: "Webhook URL (Upsale)" }]
            return urls
        }
        return []
    }

    async configureRecommendationIntegration() {
        const serviceUserUID = (await ref().child(`v1/accounts/${this.props.role.account_id}/api_keys/service_user_uid`).once("value")).val()
        if (!serviceUserUID) {
            console.error("No service user UID found on the account")
            return
        }

        // look for existing key
        const existingAPIKey = await getExistingApiKey(this.props.role.account_id)

        // Create API key
        const apiKey = existingAPIKey || uuid()
        const apiKeyRef = ref().child(`v1/accounts/${this.props.role.account_id}/api_keys/import_integrations/recommendations/raptor/${apiKey}`)
        await apiKeyRef.set({ metadata: { active: true, uid: serviceUserUID } })

        // Create integration
        const integrationRef = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/import_integrations/recommendations/raptor`)
        await integrationRef.set({ name: "Raptor Product Recommendation Import" })

        this.props.updateConfiguration(config => {
            config.api_key = apiKey
        })
    }

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] ?? false
    }

    recommendationHelpText = (
        <div key="a">
            It is easy to configure the Raptor integration:
            <ol>
                <li>
                    Tap the &quot;Configure Raptor Integration&quot; button in order to generate an API key for the integration.
                </li>
                <li>
                    When the key is generated, you will see two URLs for the integration. Copy each of them by tapping anywhere on the URL.
                </li>
                <li>
                    Paste them into an email and send them to Raptor at: <a href="mailto:support@raptorsmartadvisor.com">support@raptorsmartadvisor.com</a>.
                </li>
                <li>
                    Define 2 recommendation categories in &quot;Product Recommendations&quot; to match the category ids that Raptor uses: &quot;similar&quot; and &quot;upsale&quot;
                </li>
            </ol>
        </div>
    )

    removeConfiguration() {
        if (!window.confirm("Are you sure you wish to delete the configuration and start over?")) { return }
        this.props.updateConfiguration(data => {
            data.enabled = true 
        })
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12} sm={10} md={8}>
                        {
                            this.isEnabled() ? (
                                <div>
                                    {this.recommendationHelpText}
                                    <br /><br />
                                    {
                                        this.recommendationsConfigured() ? (
                                            <div>
                                                {this.recommendationImportURLs().map((webhook, index) => {
                                                    return (
                                                        <CopyToClipboard
                                                            key={index}
                                                            text={webhook.url}
                                                            onCopy={() => this.setState({ copied: true })}
                                                        >
                                                            <FormGroup className="mb-3" as={Row}>
                                                                <InputGroup>
                                                                    <InputGroup.Text>{webhook.name}</InputGroup.Text>
                                                                    <FormControl type="text" readOnly={true} value={webhook.url} />
                                                                    <InputGroup.Text>
                                                                        <Clipboard2Check />
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </CopyToClipboard>
                                                    )
                                                })}
                                                {this.state.copied ? <Alert variant="success"> Integration URL copied to clipboard.</Alert> : null}
                                                <br /><br />
                                                <Button variant="danger" onClick={() => { this.removeConfiguration() }}>Remove configuration and start over</Button>
                                            </div>
                                        ) : <Button onClick={async () => { await this.configureRecommendationIntegration() }}>Configure Raptor Integration</Button>

                                    }
                                </div>
                            ) : null
                        }
                    </Col>
                </Row>
            </Grid >
        )
    }
}
