import _ from "lodash"
import * as React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Form, FormGroup, Col, Button, Modal, DescriptionCol, Row } from "../../wrappers"
import { LanguageCode, L10nString } from "../../../helpers/L10n"
import { L10nFormControl } from "../../L10nFormControl"
import { StripedTable } from "../../StripedTable"
import { allShortcuts, Shortcut, ShortcutItem, shortcutName, ShortcutsSection } from "./FrontPageSectionModels"

interface ShortcutsSectionEditProps {
    section: ShortcutsSection
    currentLanguage: LanguageCode | null
    updateSection: (subSection: ShortcutsSection) => void
}

interface ShortcutsSectionEditState {
    section: ShortcutsSection
    addShortcut: boolean
}

// these css styles are a feeble attempt to make the table rows not look too bad while being dragged....

const narrowStyle = {
    width: "10%",
    textAlign: "center" as "center"
}

const nameStyle = {
    width: "80%",
    textAlign: "left" as "left"
}

export class ShortcutsSectionEdit extends React.Component<ShortcutsSectionEditProps, ShortcutsSectionEditState> {
    constructor(props: ShortcutsSectionEditProps) {
        super(props)

        this.state = {
            section: props.section,
            addShortcut: false
        }
    }

    onLocalizationChanged = (name: string, l10n: L10nString | null) => {
        const section = _.cloneDeep(this.state.section)

        if (l10n === null || l10n.value === "") {
            delete section[name]
        } else {
            section[name] = l10n
        }
        this.props.updateSection(section)
        this.setState({ section: section })
    }

    // Signals to vm

    onRemove = (index: number) => {
        const name = this.state.section.shortcuts[index].title()
        if (window.confirm(`Really remove the shortcut "${name}"?`) === true) {
            const section = _.cloneDeep(this.state.section)
            section.shortcuts.splice(index, 1)
            this.props.updateSection(section)
            this.setState({ section: section })
        }
    }

    onDragEnd = (reorder: any) => {
        // dropped outside the list
        if (!reorder.destination || reorder.destination.index === reorder.source.index) {
            return
        }

        // no movement
        if (reorder.destination.index === reorder.source.index) {
            return
        }

        const section = _.cloneDeep(this.state.section)
        const [removed] = section.shortcuts.splice(reorder.source.index, 1)
        section.shortcuts.splice(reorder.destination.index, 0, removed)

        this.props.updateSection(section)
        this.setState({ section: section })
    }

    addShortcut = (shortcut: ShortcutItem) => {
        const section = _.cloneDeep(this.state.section)
        section.shortcuts.push(new Shortcut(shortcut))

        this.props.updateSection(section)
        this.setState({ section: section, addShortcut: false })
    }

    render() {
        return [
            (
                <Form key="form" onSubmit={e => e.preventDefault()}>
                    <FormGroup className="mb-3" as={Row}>
                        <DescriptionCol sm={2}>Name</DescriptionCol>
                        <Col sm={10}>
                            <L10nFormControl
                                l10n={this.state.section.title || null}
                                language={this.props.currentLanguage || null}
                                onLocalizationChanged={l10n => {
                                    this.onLocalizationChanged("title", l10n)
                                }
                                }
                            />
                        </Col>
                    </FormGroup>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(droppableProvided) => (
                                <div ref={droppableProvided.innerRef}>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th style={narrowStyle}>Order</th>
                                                <th style={nameStyle}>Name</th>
                                                <th style={narrowStyle}>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.section.shortcuts.map((shortcut, index) => (
                                                <Draggable key={`${shortcut.id}`} draggableId={`${shortcut.id}`} index={index}>
                                                    {(draggableProvided: any) => (
                                                        <tr
                                                            ref={draggableProvided.innerRef}
                                                            key={`${shortcut.id}`}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                        >
                                                            <td style={narrowStyle}>{index + 1}</td>
                                                            <td style={nameStyle}>
                                                                {shortcut.title()}
                                                            </td>
                                                            <td style={narrowStyle}>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        this.onRemove(index)
                                                                    }}
                                                                >
                                                                    X
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </tbody>
                                    </StripedTable>
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div style={{textAlign: "right"}}>
                        <Button onClick={() => { this.setState({ addShortcut: true }) }}>Add</Button>
                    </div>                                                
                </Form>
            ),
            this.state.addShortcut ? (
                <Modal show={true} key="add">
                    <Modal.Header>
                        <Modal.Title>
                            <span>Add shortcut</span>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th style={nameStyle}>Shortcut</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allShortcuts.map((shortcut, index) => (
                                    <tr key={index}>
                                        <td style={nameStyle} onClick={() => this.addShortcut(shortcut)}>
                                            {shortcutName(shortcut)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </StripedTable>
                    </Modal.Body >

                    <Modal.Footer>
                        <Button onClick={() => this.setState({ addShortcut: false })}>Cancel</Button>
                    </Modal.Footer>

                </Modal >
            ) : null
        ]
    }
}