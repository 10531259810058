import * as React from "react"

import { FormattedTime } from "react-intl"
import { PageState } from "../../PageState"
import { Card } from "../../wrappers"
import { Button } from "../../wrappers"

import { StockCountListItem } from "./StockCountListModels"
import { StockCountListViewModel } from "./StockCountListViewModel"
import { StockCountOpenModal } from "./StockCountOpenModal"
import { StripedTable } from "../../StripedTable"
import { RoleStockLocationProps, withStockLocationRouter } from "src/routes"
import { Pagination } from "react-bootstrap"
import { StockCountFilter } from "../../../models/StockCountFilter"
import { StockCountRequest, stockCountRequestFromJSON } from "../../../models/StockCountRequest"
import { StockCountRequestPager } from "./StockCountRequestPager"
import dayjs from "dayjs"

interface StockCountListState {
    current?: StockCountListItem
    loaded: boolean
    past: StockCountListItem[],
    publishingMessage?: string
    showOpenNewModal: boolean
    requested: StockCountRequest[]
}

class StockCountList extends React.Component<RoleStockLocationProps, StockCountListState> {

    // Props

    viewModel: StockCountListViewModel

    // Constructor

    constructor(props: RoleStockLocationProps) {
        super(props)

        this.viewModel = new StockCountListViewModel(props.role.account_id, props.stockLocation)
        this.viewModel.didCompleteInitialLoad = (past, current) => {
            this.setState({ past: past, current: current, loaded: true })
        }
        this.viewModel.didLoadPastStockCounts = (past) => {
            this.setState({ past: past, loaded: true })
        }
        this.viewModel.didCreateNewStockCount = (stockCountId, errorMessage) => {
            if (!stockCountId && errorMessage) {
                alert(errorMessage)
                this.setState({ publishingMessage: undefined })

                const path = `/stock_location/${this.props.stockLocation}/stock_counts`
                this.props.router.navigate(path)
                return
            }

            if (stockCountId) {
                const path = `/stock_location/${this.props.stockLocation}/stock_count/current/${stockCountId}`
                this.props.router.navigate(path)
            }
        }

        this.state = {
            loaded: false,
            past: [],
            publishingMessage: undefined,
            showOpenNewModal: false,
            requested: []
        }
    }

    // Methods

    openNewClicked() {
        this.setState({ showOpenNewModal: true })
    }

    async openNewStockCountAndRedirect(name: string, filter: StockCountFilter) {
        this.setState({ showOpenNewModal: false, publishingMessage: "Opening a new Stock Count" })
        await this.viewModel.createNewStockCount(name, filter, (total, count, filtered) => {
            const message = `Opening a new Stock Count. Filtering products: ${count} - matches: ${filtered}`
            this.setState({ showOpenNewModal: false, publishingMessage: message })
        })
    }

    loadPrevious() {
        this.viewModel.startLoadOfPreviousPastStockCounts()
        this.setState({ loaded: false })
    }

    loadNext() {
        this.viewModel.startLoadOfNextPastStockCounts()
        this.setState({ loaded: false })
    }

    showCurrentStockCount(id?: string) {
        if (!id) {
            return
        }

        const path = `/stock_location/${this.props.stockLocation}/stock_count/current/${id}`
        this.props.router.navigate(path)
    }

    showPastStockCount(id: string) {
        const path = `/stock_location/${this.props.stockLocation}/stock_count/past/${id}`
        this.props.router.navigate(path)
    }

    componentDidMount() {
        this.viewModel.startInitialLoad()
    }

    thisMorning = dayjs().startOf("day").toDate()

    render() {
        return (
            <PageState loading={!(this.state.loaded || false)} publishing={false} customMessage={this.state.publishingMessage} typeName="stock counts">
                <Card className="my-4">
                    <Card.Header>
                        Currently open stock count
                    </Card.Header>
                    {
                        this.state.current ? (
                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Opened by</th>
                                        <th>Opened at</th>
                                        <th>Due by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={this.state.current.id} onClick={() => this.showCurrentStockCount((this.state.current || { id: undefined }).id)}>
                                        <td>{this.state.current.name}</td>
                                        <td>{this.state.current.openendBy}</td>
                                        <td>
                                            <FormattedTime
                                                value={new Date(this.state.current.openedAt * 1000)}
                                                day="numeric"
                                                month="long"
                                                year="numeric"
                                                hour="numeric"
                                                minute="numeric"
                                            />
                                        </td>
                                        <td>
                                            {this.state.current.requestDueDate &&
                                                <span style={{ color: this.state.current.requestDueDate < this.thisMorning ? "#FF0000" : "black" }}>
                                                    <FormattedTime
                                                        value={this.state.current.requestDueDate}
                                                        day="numeric"
                                                        month="long"
                                                        year="numeric"
                                                        hour="numeric"
                                                        minute="numeric"
                                                    />
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </StripedTable>
                        ) : (
                            <Card.Body>
                                <Button onClick={() => { this.openNewClicked() }}>Open stock count</Button>
                            </Card.Body>
                        )
                    }
                </Card>
                {this.renderStockCountRequests()}
                <hr />
                {this.renderPastStockCounts()}
                {
                    this.state.showOpenNewModal ? (
                        <StockCountOpenModal
                            mode="stock_count"
                            stockLocation={this.props.stockLocation}
                            role={this.props.role}
                            openNew={async (name: string, filter: StockCountFilter, shops: Set<string>) => { await this.openNewStockCountAndRedirect(name, filter) }}
                            cancel={() => { this.setState({ showOpenNewModal: false }) }}
                        />
                    ) : null
                }
            </PageState>
        )
    }

    async openStockCountFromRequest(request: StockCountRequest) {
        this.setState({ showOpenNewModal: false, publishingMessage: "Creating a Stock Count from request" })
        await this.viewModel.createNewStockCountFromRequest(request, (total, count, filtered) => {
            const message = `Creating a Stock Count from request. Filtering products: ${count} - matches: ${filtered}`
            this.setState({ showOpenNewModal: false, publishingMessage: message })
        })
    }

    renderStockCountRequests() {
        return <StockCountRequestPager
            accountId={this.props.role.account_id}
            stockLocation={this.props.stockLocation}
            openStockCount={async request => { await this.openStockCountFromRequest(request) }}
            hasCurrentCount={this.state.current !== undefined}
        />
    }

    renderPastStockCounts() {
        return (<div><Card className="my-4">
            <Card.Header>
                Past stock counts
            </Card.Header>
            <StripedTable>
                <thead>
                    <tr>
                        {/* <th>Id</th> */}
                        <th>Name</th>
                        <th>Opened by</th>
                        <th>Opened at</th>
                        <th>Closed by</th>
                        <th>Closed at</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.past.map(listItem => {
                        let lineProps: React.CSSProperties = {}
                        if (listItem.cancelled || false) {
                            lineProps = { color: "#CCCCCC" }
                        }
                        const result = (
                            <tr style={lineProps} key={listItem.id} onClick={() => this.showPastStockCount(listItem.id)}>
                                {/* <td>{listItem.id}</td> */}
                                <td>{listItem.name + ((listItem.cancelled || false) ? " (cancelled)" : "")}</td>
                                <td>{listItem.openendBy}</td>
                                <td>
                                    <FormattedTime
                                        value={new Date(listItem.openedAt * 1000)}
                                        day="numeric"
                                        month="long"
                                        year="numeric"
                                        hour="numeric"
                                        minute="numeric"
                                    />
                                </td>
                                <td>{listItem.closedBy}</td>
                                <td>
                                    <FormattedTime
                                        value={new Date((listItem.closedAt || 0) * 1000)}
                                        day="numeric"
                                        month="long"
                                        year="numeric"
                                        hour="numeric"
                                        minute="numeric"
                                    />
                                </td>
                            </tr>
                        )
                        return result
                    })}
                </tbody>
            </StripedTable>
        </Card>
            <Pagination>
                <Pagination.Prev onClick={() => { this.loadPrevious() }} disabled={this.viewModel.isPreviousDisabled()}>&larr; Previous Page</Pagination.Prev>
                <Pagination.Next onClick={() => { this.loadNext() }} disabled={this.viewModel.isNextDisabled()}>Next Page &rarr;</Pagination.Next>
            </Pagination>
        </div>
        )
    }
}

export default withStockLocationRouter(StockCountList)