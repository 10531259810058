import React, { useState } from "react";
import { Form } from "react-bootstrap";
import _, { keys } from "lodash";
import { Button, Col, DescriptionCol, FormControl, FormGroup, Row } from "src/components/wrappers";
import { Margins } from "src/models/pdf_template/margins";
import { GlobalEditProps } from "./GlobalModalEdit";
import { StripedTable } from "src/components/StripedTable";
import { DeleteButton } from "src/components/DeleteButton";

export function EditAvailableFonts(props: GlobalEditProps) {
    const [newFontName, setNewFontName] = useState<string>()
    const [newFontFile, setNewFontFile] = useState<string>()

    return (
        <div>
        <StripedTable>
            <thead>
                <tr>
                    <th style={{fontWeight:"bold"}}>Font name</th>
                    <th style={{fontWeight:"bold"}}>Font file</th>
                    <th style={{fontWeight:"bold"}}></th>
                </tr>
            </thead>
            <tbody>
                {
                    getAvailableFonts(props.pdfTemplate.fontMap).map(font => {
                        return (
                            <tr key={font.fontName}>
                                <td>{font.fontName}</td>
                                <td>{font.fontFile}</td>
                                <td> <DeleteButton onDelete={() => {onDelete(font.fontName)}}></DeleteButton></td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </StripedTable>
        <Form>
        <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Font name</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Font name"
                        value={newFontName ?? ""}
                        placeholder="Enter new font name"
                        onChange={(event) => {
                            const value = event.currentTarget.value as string
                            setNewFontName(value) 
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Font file</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Font name"
                        value={newFontFile ?? ""}
                        placeholder="Enter new font file"
                        onChange={(event) => {
                            const value = event.currentTarget.value as string
                            setNewFontFile(value)
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label></Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <Button style={{float: "right"}} onClick={ () => {addNewFont()}}> Add new font</Button>
                </Col>
            </FormGroup>
        </Form>
        </div>
    );

    function addNewFont() {
        if (_.isEmpty(newFontName) || _.isUndefined(newFontName)) { return }
        if (_.isEmpty(newFontFile) || _.isUndefined(newFontFile)) { return }
        const updatedFontMap = _.cloneDeep(props.pdfTemplate.fontMap)
        updatedFontMap.set(newFontName, newFontFile)
        
        setNewFontFile(undefined)
        setNewFontName(undefined)

        props.updatePDFTemplate((template) => {
            template.fontMap = updatedFontMap
            return template
        })
    }

    function onDelete(font: string) {
        const updatedFontMap = _.cloneDeep(props.pdfTemplate.fontMap)
        updatedFontMap.delete(font)

        props.updatePDFTemplate((template) => {
            template.fontMap = updatedFontMap
            return template
        })
    }
}

interface AvailableFont {
    fontName: string
    fontFile: string
}

function getAvailableFonts(fontMap: Map<string, string>): AvailableFont[]{
    const keys = Array.from(fontMap.keys()).sort()

    const availableFonts: AvailableFont[] = keys.map((key) => {
        return {
            fontName: key,
            fontFile: fontMap.get(key) ?? ""
        }
    })

    return availableFonts
}

