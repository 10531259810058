import * as React from "react"
import ImageDropControl from "../ImageDropControl"
import {
    Attribute,
    AttributeValue,
    Dimension,
    MarketTaxes,
    Product,
    Unit,
    UnitPricing,
    Variant
} from "../../models/Product"
import { AttributeSelection } from "./AttributeSelection"
import { Button, Modal, Col, Card, DropdownButton, Row, Stack, Form, Container } from "react-bootstrap"
import { ChannelSelector } from "../ChannelSelector"
import {
    cloneDeep,
    concat,
    Dictionary,
    isNil
} from "lodash"
import { DimensionEdit } from "./DimensionEdit"
import { Globals } from "../../helpers/globals"
import { GroupSelection } from "../GroupSelection"
import { L10nFormControl } from "../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import {
    Market,
    Tax
} from "../../models/MarketModels"
import { MarketAmount } from "../../models/MarketAmount"
import { MarketAmountFormControl } from "../MarketAmountFormControl"
import { Metadata } from "../../models/RuleModels"
import { PageState } from "../PageState"
import { ProductGalleryEdit } from "./ProductGalleryEdit"
import { ProductDocumentAssetsEdit } from "./ProductDocumentAssetsEdit"
import { productName } from "../../helpers/productName"
import { ref } from "../../config/constants"
import { StripedTable } from "../StripedTable"
import { TagSelection } from "./TagSelection"
import { VariantEdit } from "./VariantEdit"
import { ProductVideoAssetsEdit } from "./ProductVideoAssetsEdit"
import { ProductAsset } from "../../models/ProductAsset"
import { v4 as uuidv4 } from "uuid"
import { RoleRouterProps, withRoleRouter } from "src/routes"
import { MarketPicker } from "../MarketPicker"
import { Dropdown } from "react-bootstrap"
import * as _ from "lodash"
import { DeleteButtonSymbol } from "./DiscountRules/AppliesToSelector"

function cartesian(args: any[]) {
    const r: any[] = [], max = args.length - 1
    function helper(arr: any[], i: number) {
        for (let j = 0, l = args[i].length; j < l; j++) {
            const a = arr.slice(0) // clone arr
            a.push(args[i][j])
            if (i === max)
                r.push(a)
            else
                helper(a, i + 1)
        }
    }
    helper([], 0)
    return r
}

function trimUndefinedFromObject(obj: any) {
    if (!Array.isArray(obj) && typeof obj !== "object") return obj
    return Object.keys(obj).reduce(function (acc, key) {
        if (!obj[key] && obj[key] !== 0) {
            return acc
        }

        if (typeof obj[key] === "object") {
            acc[key] = trimUndefinedFromObject(obj[key])
        } else {
            acc[key] = obj[key]
        }
        return acc
    }, Array.isArray(obj) ? [] : {})
}

const pushid = require("pushid")

interface LabelledControlProps {
    label?: string | JSX.Element
    hidden?: boolean
    children?: JSX.Element | JSX.Element[]
}

export function LabelledControl(props: LabelledControlProps) {
    return <>
        {
            (props.hidden !== true) && <Form.Group className="mb-3">
                {props.label &&
                    <Form.Label className="text-muted">{props.label}</Form.Label>
                }
                {props.children}
            </Form.Group>
        }
    </>
}


interface ProductEditProps extends RoleRouterProps {
    isNew?: boolean
    shop?: string // XXX TODO, ARE WE USING THIS STILL?
}

interface ProductEditState {
    attributes: Dictionary<Attribute>
    markets: Market[]
    currentMarket: Market | null
    currentLanguage: LanguageCode | null
    variantEditIndex: number | null
    dimensionEditIndex: number | null
    galleryEdit: boolean
    videoAssetsEdit: boolean
    documentAssetsEdit: boolean
    product: Product
    metadata: Metadata
    imageAssets: ProductAsset[]
    videoAssets: ProductAsset[]
    documentAssets: ProductAsset[]
    loaded: boolean
    publishing: boolean
    dirty: boolean
    taxes: Tax[]
    showAlertForMissingMarket: boolean
    isNew: boolean,
    pricingMode: "regular" | "variable_price" | "unit_price"
}

class ProductEdit extends React.Component<ProductEditProps, ProductEditState> {

    constructor(props: ProductEditProps) {
        super(props)

        const key = props.router.params.productKey
        const product = new Product(null)
        product.id = key

        this.state = {
            attributes: {},
            markets: [],
            currentMarket: null,
            currentLanguage: null,
            variantEditIndex: null,
            dimensionEditIndex: null,
            galleryEdit: false,
            videoAssetsEdit: false,
            documentAssetsEdit: false,
            product: product,
            metadata: {
                channels: {},
                markets: {}
            },
            taxes: [],
            imageAssets: [],
            videoAssets: [],
            documentAssets: [],
            loaded: false,
            publishing: false,
            dirty: false,
            showAlertForMissingMarket: false,
            isNew: props.isNew === true,
            pricingMode: "regular"
        }
    }

    updateCurrentMarket(selectedMarkets: string[]): Market | null {
        let currentMarket = this.state.currentMarket
        let currentMarketKey = currentMarket?.id ?? null

        if (selectedMarkets.length < 1) {
            return null
        }

        if (currentMarketKey && !selectedMarkets.includes[currentMarketKey]) {
            currentMarketKey = null
        }

        if (currentMarketKey === null) {
            currentMarketKey = selectedMarkets[0]
        }
        currentMarket = this.state.markets.find(market => { return (market.id === currentMarketKey) }) || null
        return currentMarket
    }

    resolvedCurrency(): string {
        const market = this.resolvedMarket()
        return market?.currency ?? ""
    }

    resolvedMarket(): Market | null {
        if (this.state.currentMarket) {
            return this.state.currentMarket
        }
        if (this.state.markets.length === 1) {
            return this.state.markets[0]
        }
        const selectedMarketKeys = Object.keys(this.state.metadata.markets)
        if (selectedMarketKeys.length === 1) {
            return this.state.markets.find(market => { return market.id === selectedMarketKeys[0] }) || null
        }
        return null
    }

    productsRef() {
        const account = this.props.role.account_id
        const shop = this.props.shop
        if (isNil(shop)) {
            return ref().child(`v1/accounts/${account}/inventory/product_repo`)
        } else {
            return ref().child(`v1/accounts/${account}/shops/${shop}/inventory/products`)
        }
    }

    isProductRepo(): boolean {
        const shop = this.props.shop
        if (isNil(shop)) {
            return true
        } else {
            return false
        }
    }

    pop() {
        this.props.router.navigate(-1)
    }

    post = async () => {
        const key = this.state.product.id

        // Product

        const product = _.clone(this.state.product)
        if (this.state.pricingMode !== "regular") {
            delete product.retail_price
            delete product.sale_price
            delete product.cost_price
            delete product.variants
            delete product.dimensions
        }
        if (this.state.pricingMode !== "unit_price") {
            delete product.unit_pricing
        }

        const productData = {
            product: trimUndefinedFromObject(product.json()),
            metadata: this.state.metadata
        }

        const promises: Promise<any>[] = []

        const productRef = this.productsRef().child(key)
        if (this.isProductRepo()) {
            promises.push(productRef.set(productData))
        } else {
            const product = productData.product
            promises.push(productRef.set(product))
        }

        // Product assets
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        if (this.isProductRepo()) {
            const updatedAssets = concat(this.state.documentAssets, this.state.imageAssets, this.state.videoAssets).map((asset) => { return asset.toJSON() })
            const productAssetRef = accountRef.child(`inventory/product_assets/${key}`)
            promises.push(productAssetRef.set(updatedAssets))
        }

        this.setState({ publishing: true })
        await Promise.all(promises)
        this.pop()
    }

    async componentDidMount() {
        const key = this.props.router.params.productKey
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        let productData: any | undefined
        if (!this.state.isNew) {
            const productRef = this.productsRef().child(key)
            const productSnap = await productRef.once("value")
            productData = productSnap.val()
        }

        const taxes = await Globals.shared.getTaxes()
        const markets = await Globals.shared.getMarkets()
        const channels = this.isProductRepo() ? await Globals.shared.getChannels() : []

        if (this.isProductRepo()) {
            this.setState({ markets: markets, taxes: taxes, loaded: true })
        } else {
            const shopMarketSnap = await ref().child(`v1/accounts/${this.props.role.account_id}/shops/${this.props.shop}/market`).once("value")
            const shopMarketId = shopMarketSnap.val() || ""
            const market = markets.find(m => { return m.id === shopMarketId }) || null

            if (!market) {
                this.setState({ showAlertForMissingMarket: true })
            } else {
                this.setState({ markets: [market], taxes: taxes, loaded: true })
            }
        }

        const attributesRef = ref().child(`v1/accounts/${account}/inventory/attributes`)
        const snapshot = await attributesRef.once("value")
        const attributes: Dictionary<Attribute> = {}
        if (snapshot.exists()) {
            const rawAttributes = snapshot.val()
            for (const k in rawAttributes) {
                if (Object.prototype.hasOwnProperty.call(rawAttributes, k)) {
                    attributes[k] = new Attribute(rawAttributes[k])
                }
            }
            this.setState({ attributes: attributes })
        }

        if (productData) {
            if (this.isProductRepo()) {
                const metadata = { channels: {}, markets: {} }
                if (productData.metadata) {
                    if (productData.metadata.channels) {
                        metadata.channels = productData.metadata.channels
                    }
                    if (productData.metadata.markets) {
                        metadata.markets = productData.metadata.markets
                    }
                }
                if (markets.length === 1) {
                    metadata.markets = { [markets[0].id]: true }
                }
                if (channels.length === 1) {
                    metadata.channels = { [channels[0].id]: true }
                }

                const product = new Product(productData.product)

                const selectedMarkets: string[] = Object.keys(metadata.markets)
                product.setMarkets(selectedMarkets)

                const currentMarket = this.updateCurrentMarket(selectedMarkets)
                this.setState({
                    product: product,
                    metadata: metadata,
                    currentMarket: currentMarket,
                    pricingMode: product.pricingMode()
                })
            } else {
                const product = new Product(productData)

                this.setState({
                    product: product,
                    pricingMode: product.pricingMode()
                })
            }
        }

        if (this.isProductRepo() && !this.state.isNew) {
            const productAssetRef = accountRef.child("inventory").child("product_assets").child(key)
            const assetsSnap = await productAssetRef.once("value")

            const documentAssets: ProductAsset[] = []
            const imageAssets: ProductAsset[] = []
            const videoAssets: ProductAsset[] = []
            if (assetsSnap.exists()) {
                for (const json of assetsSnap.val()) {
                    const asset = ProductAsset.fromJSON(json)

                    if (isNil(asset)) {
                        continue
                    }

                    if (asset.isDocumentAsset()) {
                        documentAssets.push(asset)
                    } else if (asset.isImageAsset()) {
                        imageAssets.push(asset)
                    } else if (asset.isVideoAsset()) {
                        videoAssets.push(asset)
                    }
                }
            }

            this.setState({
                documentAssets: documentAssets,
                imageAssets: imageAssets,
                videoAssets: videoAssets,
            })
        }

        if (this.isProductRepo() && this.state.isNew) {
            const metadata = { channels: {}, markets: {} }
            if (markets.length === 1) {
                metadata.markets = { [markets[0].id]: true }
            }
            if (channels.length === 1) {
                metadata.channels = { [channels[0].id]: true }
            }
            this.setState({
                metadata: metadata
            })
        }
    }

    setProductImage = (url: string | null) => {
        const product = this.state.product
        product["image_url"] = url || undefined
        this.setState({ product: product, dirty: true })
    }

    handleProductInputChange = (event: any) => {
        this.productEdit(product => {

            const target = event.target
            let value = target.value
            const name = target.name

            if (value === "") {
                delete product[name]
            } else {

                if (target.type === "number") {
                    value = Number(value)
                }

                product[name] = value
            }
        })
    }

    handleUnitPricingChange = (event: any) => {
        this.productEdit(product => {
            const unitPricing = product.unit_pricing ?? new UnitPricing({ unit: "mass/g" })

            const target = event.target
            let value = target.value
            const name = target.name

            if (value === "") {
                delete unitPricing[name]
            } else {

                if (target.type === "number") {
                    value = Number(value)
                }
                if (target.name === "scale") {
                    value = Math.round(value)
                }
                if (target.name === "multiplicity") {
                    value = Math.abs(value)
                    if (value === 0) {
                        value = 1
                    }
                }

                unitPricing[name] = value
            }
            product.unit_pricing = unitPricing
        })
    }

    handleChannelsChange = (data: any) => {
        const channels = {}
        for (const channel in data) {
            channels[data[channel]] = true
        }

        const metadata = this.state.metadata
        metadata.channels = channels

        this.setState({ metadata: metadata, dirty: true })
    }

    handleCurrentMarketChange(market: Market | null) {
        this.setState({ currentMarket: market })
    }

    addMarket = (market: string) => {
        const metadata = cloneDeep(this.state.metadata)
        const product = cloneDeep(this.state.product)
        metadata.markets[market] = true
        product.setMarkets(Object.keys(metadata.markets))
        const currentMarket = this.updateCurrentMarket(Object.keys(metadata.markets))
        this.setState({ metadata: metadata, product: product, currentMarket: currentMarket, dirty: true })
    }

    removeMarket = (market: string) => {
        const metadata = cloneDeep(this.state.metadata)
        const product = cloneDeep(this.state.product)
        delete metadata.markets[market]
        const remaining = Object.keys(metadata.markets)
        product.removeMarket(market, remaining)
        const currentMarket = this.updateCurrentMarket(Object.keys(metadata.markets))
        this.setState({ metadata: metadata, product: product, currentMarket: currentMarket, dirty: true })
    }

    handleTagsChange = (tags: Dictionary<boolean>) => {
        this.productEdit(product => { product.tags = tags })
    }

    handleAttributesChange = (attributes: Dictionary<AttributeValue>) => {
        this.productEdit(product => { product.attributes = attributes })
    }

    handleGroupChange = (group: string | null) => {
        this.productEdit(product => { product.product_group = group || undefined })
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })

        if (isNil(language)) { return }
        const languages = this.resolveLanguages(this.state.product)
        if (!languages.includes(language)) {
            this.productEdit(product => {
                product.localizeTo(language, this.state.attributes)
            })
        }
    }

    productEdit(closure: ((product: Product) => any | null | undefined)) {
        const product = cloneDeep(this.state.product)
        const state = closure(product) || {}
        state["product"] = product
        state["dirty"] = true
        this.setState(state)
    }

    removeLanguage = (language: LanguageCode) => {
        const videoAssets = cloneDeep(this.state.videoAssets)
        videoAssets.forEach((asset) => { asset.removeLocalization(language) })

        const documentAssets = cloneDeep(this.state.documentAssets)
        documentAssets.forEach((asset) => { asset.removeLocalization(language) })

        const imageAssets = cloneDeep(this.state.imageAssets)
        imageAssets.forEach((asset) => { asset.removeLocalization(language) })

        this.productEdit(product => {
            product.removeLocalization(language)
            return {
                currentLanguage: null,
                videoAssets: videoAssets,
                documentAssets: documentAssets,
                imageAssets: imageAssets
            }
        })
    }

    onLocalizationChanged = (name: string, l10n: L10nString | null) => {
        const product = cloneDeep(this.state.product)

        if (l10n === null || l10n.value === "") {
            delete product[name]
        } else {
            product[name] = l10n
        }
        this.setState({ product: product, dirty: true })
    }

    onAmountChanged = (name: string, amount: MarketAmount | null) => {
        this.productEdit(product => {
            if (amount === null) {
                delete product[name]
            } else {
                product[name] = amount
            }
        })
    }

    onUnitPriceAmountChanged = (name: string, amount: MarketAmount | null) => {
        this.productEdit(product => {
            const unitPrice = product.unit_pricing ?? new UnitPricing({ unit: "mass/g" })
            if (amount === null) {
                delete unitPrice[name]
            } else {
                unitPrice[name] = amount
            }
            product.unit_pricing = unitPrice
        })
    }

    publishVariant = (variant: Variant) => {
        this.productEdit(product => {
            const variants = product.variants || []
            if (variant === null || this.state.variantEditIndex === null) {
                return
            }

            variants[this.state.variantEditIndex] = variant
            product.variants = variants
        })
    }

    closeDialog = () => {
        this.setState({ dimensionEditIndex: null, variantEditIndex: null, galleryEdit: false, videoAssetsEdit: false, documentAssetsEdit: false })
    }

    addDimension = () => {
        // TODO: Add default value to all variants??
        this.setState({ dimensionEditIndex: (this.state.product.dimensions || []).length })
    }

    addVariantsForAllDimensions = () => {
        this.productEdit(product => {
            const variants = product.variants ?? []
            const arrays = (product.dimensions ?? []).map(dim => { return dim.values.map(val => { return val.id }) })
            const combinations = cartesian(arrays)
            for (const combination of combinations) {
                const variant = new Variant({ id: uuidv4().toUpperCase() })
                const vals: _.Dictionary<string> = {}
                for (const index in combination) {
                    const dimension = this.state.product.dimensions![index]
                    vals[dimension.id] = combination[index]
                }
                variant.dimension_values = vals
                variants.push(variant)
            }
            product.variants = variants
        })
    }

    editDimension = (dimensionIndex: number) => {
        this.setState({ dimensionEditIndex: dimensionIndex })
    }

    publishDimension = (dimension: Dimension) => {
        this.productEdit(product => {
            const index = this.state.dimensionEditIndex
            const dimensions = product.dimensions || []

            if (dimension === null || index === null) {
                return
            }

            dimensions[index] = dimension
            product.dimensions = dimensions

            // Remove dangling references from any deleted dimension values
            // Run through all variants, their dimension values and try to find them in the dimensions on the product
            const variants = product.variants || []
            variants.forEach((variant: Variant) => {
                const values = variant.dimension_values || {}
                for (const dimensionKey of Object.keys(values)) {
                    const valueId = values[dimensionKey]
                    let found = false
                    for (const d of dimensions) {
                        if (d.id !== dimensionKey) {
                            continue
                        }
                        for (const v of d.values) {
                            if (v.id === valueId) {
                                found = true
                                break
                            }
                        }
                        if (found) {
                            break
                        }
                    }
                    if (!found) {
                        delete values[dimensionKey]
                    }
                }
            })
        })
    }

    removeDimension = (dimensionIndex: number) => {
        this.productEdit(product => {
            if (product.dimensions === undefined) {
                return
            }
            const d = product.dimensions[dimensionIndex]
            product.dimensions.splice(dimensionIndex, 1)
            if (product.variants) {
                product.variants.forEach((v: Variant) => {
                    v.removeDimensionValues(d)
                })
            }
        })
    }

    addVariant = () => {
        this.setState({ variantEditIndex: (this.state.product.variants || []).length })
    }

    editVariant = (variantIndex: number) => {
        this.setState({ variantEditIndex: variantIndex })
    }

    removeVariant = (variantIndex: number) => {
        this.productEdit(product => {
            if (product.variants === undefined) {
                return
            }

            product.variants.splice(variantIndex, 1)
        })
    }

    editedDimension = (): Dimension | null => {
        if (this.state.dimensionEditIndex === null) { return null }

        const dimensions = this.state.product.dimensions || []
        if (dimensions.length > this.state.dimensionEditIndex) {
            const dimension = dimensions[this.state.dimensionEditIndex]
            if (dimension.values === undefined) {
                dimension.values = []
            }
            return cloneDeep(dimension)
        } else {
            return null
        }
    }

    editedVariant = (): Variant | null => {
        if (this.state.variantEditIndex === null) {
            return null
        }
        const variants = this.state.product.variants || []
        if (variants.length > this.state.variantEditIndex) {
            return cloneDeep(variants[this.state.variantEditIndex])
        } else {
            return null
        }
    }

    editGallery = () => {
        this.setState({ galleryEdit: true })
    }

    editVideoAssets = () => {
        this.setState({ videoAssetsEdit: true })
    }

    editDocumentAssets = () => {
        this.setState({ documentAssetsEdit: true })
    }

    removeImage = () => {
        this.productEdit(product => {
            delete product.image_url
        })
    }

    toggleTax = (taxKey: string) => {
        this.productEdit(product => {
            const candidate = this.state.taxes.find(tax => { return tax.id === taxKey })
            if (candidate !== undefined) {
                const marketId = this.state.currentMarket ? this.state.currentMarket.id : null
                let existing = _.cloneDeep(product.taxes?.values(marketId)) ?? []
                if (existing.find(a => { return candidate.id === a.id }) !== undefined) {
                    existing = existing.filter(a => { return a.id !== candidate.id })
                } else {
                    existing.push(candidate)
                }

                if (product.taxes) {
                    product.taxes = product.taxes.setValues(existing, marketId) || undefined
                } else {
                    product.taxes = MarketTaxes.create(existing, marketId)
                }

            }

        })
    }

    selectedTaxes(): string[] {
        if (!this.state.product.taxes) {
            return []
        }
        const marketId = this.state.currentMarket ? this.state.currentMarket.id : null
        if (!this.state.product.taxes.hasValuesFor(marketId)) {
            return []
        }
        const taxes = this.state.product.taxes.values(marketId) || []

        return taxes.map(tax => { return tax.id })
    }

    selectedTaxesDescription(): string {
        const t = this.selectedTaxes()
        if (t.length === 0) {
            const market = this.resolvedMarket()
            if (!market) {
                return "Using default taxes for market"
            }
            const taxes: Tax[] = []
            for (const tax of this.state.taxes) {
                if (market.taxes.has(tax.id)) {
                    taxes.push(tax)
                }
            }
            const taxNames = taxes.map(tax => { return `${tax.name}: ${tax.rate * 100}%` }).join(", ")

            return `Using default taxes (${taxNames}) for market: ${market.name}`

        } else if (t.length === 1) {
            return this.state.taxes.find(a => { return a.id === t[0] })?.name ?? t[0]
        } else {
            return `Multiple taxes selected: ${t.length}`
        }
    }

    resolveLanguages = (product: Product | null): LanguageCode[] => {
        if (product === null) { return [] }
        const localizations = new Set<LanguageCode>()
        const properties = ["name", "short_description", "description"]
        properties.forEach(key => {
            const l10n: L10nString | null = product[key]
            if (l10n) {
                l10n.localizations().forEach(language => {
                    localizations.add(language)
                })

            }
        })
        if (product.dimensions) {
            product.dimensions.forEach((dimension) => {
                dimension.name.localizations().forEach(language => {
                    localizations.add(language)
                })
                dimension.values.forEach((value) => {
                    value.name.localizations().forEach(language => {
                        localizations.add(language)
                    })
                })
            })
        }
        if (product.variants) {
            product.variants.forEach((variant) => {
                if (variant.name) {
                    variant.name.localizations().forEach(language => {
                        localizations.add(language)
                    })
                }
                if (variant.attributes) {
                    const attrs = variant.attributes
                    Object.keys(attrs).forEach(key => {
                        const value = attrs[key]
                        if (value.localized) {
                            value.localized.localizations().forEach(language => {
                                localizations.add(language)
                            })
                        }
                    })
                }
            })
        }

        if (product.attributes) {
            const attrs = product.attributes
            Object.keys(attrs).forEach(key => {
                const value = attrs[key]
                if (value.localized) {
                    value.localized.localizations().forEach(language => {
                        localizations.add(language)
                    })
                }
            })
        }

        return Array.from(localizations).sort()
    }

    selectPricingMode(mode: "regular" | "variable_price" | "unit_price") {
        this.setState({ pricingMode: mode, dirty: true })
    }

    pricingModeName() {
        switch (this.state.pricingMode) {
            case "regular":
                return "Regular"
            case "variable_price":
                return "Variable price"
            case "unit_price":
                return "Unit price"
        }
    }

    unitPriceUnits(): Unit[] {
        return [
            "mass/g",
            "mass/kg",
            "area/cm2",
            "area/m2",
            "area/mm2",
            "length/cm",
            "length/m",
            "length/mm",
            "volume/cl",
            "volume/dl",
            "volume/l",
            "volume/m3",
            "volume/ml",
            "energy/kwh"
        ]
    }

    selectUnitPriceUnit(unit: Unit) {
        this.productEdit(product => {
            const unitPricing = product.unit_pricing ?? new UnitPricing({ unit: unit })
            unitPricing.unit = unit
            product.unit_pricing = unitPricing
        })
    }

    selectedUnitPriceUnit() {
        const unit = this.state.product.unit_pricing?.unit ?? "mass/g"
        return this.unitTitle(unit)
    }

    selectedUnitPriceShortName() {
        const unit = this.state.product.unit_pricing?.unit ?? "mass/g"
        return this.unitShortNamePlural(unit)
    }

    selectedUnitPriceWithMultiplicity() {
        const unit = this.state.product.unit_pricing?.unit ?? "mass/g"
        const multiplicity = this.state.product.unit_pricing?.multiplicity ?? 1
        if (multiplicity === 1) {
            return this.unitShortName(unit)
        } else {
            return `${multiplicity} ${this.unitShortNamePlural(unit)}`
        }
    }

    unitTitle(unit: Unit) {
        switch (unit) {
            case "mass/g":
            case "mass/kg":
                return `Mass in ${this.unitShortNamePlural(unit)}`
            case "area/cm2":
            case "area/m2":
            case "area/mm2":
                return `Area in ${this.unitShortNamePlural(unit)}`
            case "length/cm":
            case "length/m":
            case "length/mm":
                return `Length in ${this.unitShortNamePlural(unit)}`
            case "volume/cl":
            case "volume/dl":
            case "volume/l":
            case "volume/m3":
            case "volume/ml":
                return `Volume in ${this.unitShortNamePlural(unit)}`
            case "energy/kwh":
                return `Energy in ${this.unitShortNamePlural(unit)}`
        }
    }

    unitShortNamePlural(unit: Unit) {
        return this.unitShortName(unit) + "s"
    }

    unitShortName(unit: Unit) {
        switch (unit) {
            case "mass/g":
                return "gram"
            case "mass/kg":
                return "kilogram"
            case "area/cm2":
                return "square centimeter"
            case "area/m2":
                return "square meter"
            case "area/mm2":
                return "square milimeter"
            case "length/cm":
                return "centimeter"
            case "length/m":
                return "meter"
            case "length/mm":
                return "milimeter"
            case "volume/cl":
                return "centiliter"
            case "volume/dl":
                return "deciliter"
            case "volume/l":
                return "liter"
            case "volume/m3":
                return "cubic meter"
            case "volume/ml":
                return "mililiter"
            case "energy/kwh":
                return "kilowatt hour"
        }
    }

    renderUnitPricing() {
        return <>

            <Card.Title>
                Pricing details
            </Card.Title>
            <Card.Text>
                <p>For unit priced products, you may sell products by mass, size, volume and more.</p>
                <p>If it is natural to sell the product by 100 grams rather than by the gram, you may also select the number of the units you get for the specified price.</p>
                <p>Finally, you may select the number of decimals to allow when entering the value in POS. For instance you may sell milk in jugs by the liter (no decimals) - or you may sell water in cubic meters entered with three decimals</p>
            </Card.Text>


            <LabelledControl label="Select unit">
                <DropdownButton variant="outline-primary" title={this.selectedUnitPriceUnit()} onSelect={(event: any) => { this.selectUnitPriceUnit(event) }}>
                    {this.unitPriceUnits().map(unit => {
                        return <Dropdown.Item key={unit} eventKey={unit}>{this.unitTitle(unit)}</Dropdown.Item>
                    })}
                </DropdownButton>
            </LabelledControl>

            <LabelledControl label={<>Select the retail price per <b>count</b> of {this.selectedUnitPriceShortName()} (the count is specified below)</>}>
                <MarketAmountFormControl
                    amount={this.state.product.unit_pricing?.retail_price_per_unit ?? null}
                    market={this.resolvedMarket()}
                    allMarkets={Object.keys(this.state.metadata.markets)}
                    onAmountChanged={amount => {
                        this.onUnitPriceAmountChanged("retail_price_per_unit", amount)
                    }}
                    placeholder="Enter unit retail price"
                    currency={this.resolvedCurrency()}
                />
            </LabelledControl>

            <LabelledControl label={<>Select the cost price per <b>count</b> of units (the count is specified below)</>}>
                <MarketAmountFormControl
                    amount={this.state.product.unit_pricing?.cost_price_per_unit ?? null}
                    market={this.resolvedMarket()}
                    allMarkets={Object.keys(this.state.metadata.markets)}
                    onAmountChanged={amount => {
                        this.onUnitPriceAmountChanged("cost_price_per_unit", amount)
                    }}
                    placeholder="Enter unit cost price"
                    currency={this.resolvedCurrency()}
                />
            </LabelledControl>


            <LabelledControl label={<>Select the number of <b>{this.selectedUnitPriceShortName()}</b> that you sell for the price specified above</>}>
                <Form.Control
                    type="number"
                    name="multiplicity"
                    step={1}
                    min={1}
                    value={this.state.product.unit_pricing?.multiplicity ?? ""}
                    placeholder="Number of units"
                    onChange={this.handleUnitPricingChange}
                    autoComplete="off"
                />
            </LabelledControl>

            <LabelledControl label="Select the number of decimals to measure the unit in">
                <Form.Control
                    type="number"
                    name="scale"
                    step={1}
                    min={0}
                    value={_.isNil(this.state.product.unit_pricing?.scale) ? "" : this.state.product.unit_pricing?.scale}
                    placeholder="Number of decimals"
                    onChange={this.handleUnitPricingChange}
                    autoComplete="off"
                />
            </LabelledControl>

            <Card.Text><b>The price will be {this.state.product.unit_pricing?.retail_price_per_unit?.amount(this.state.currentMarket?.id ?? null) ?? 0} per {this.selectedUnitPriceWithMultiplicity()}</b></Card.Text>

        </>
    }

    submitDisabled(): boolean {
        if (this.state.pricingMode === "regular") {
            if (this.state.product.variants === undefined || this.state.product.variants.length === 0) {
                for (const market of Object.keys(this.state.metadata.markets)) {
                    if (this.state.product.retailPriceAmount(market) === null) {
                        return true
                    }
                }
            }
        }
        if (this.state.pricingMode === "unit_price") {
            for (const market of Object.keys(this.state.metadata.markets)) {
                if ((this.state.product.unit_pricing?.retail_price_per_unit?.hasAmountFor(market) ?? false) === false) {
                    return true
                }
            }
        }
        return false
    }

    renderContent() {
        return <>
            <div>

                <Card className="mb-4">

                    <Card.Body>

                        <LabelledControl label="Id">
                            <Form.Control type="text" readOnly disabled value={this.state.product.id} />
                        </LabelledControl>

                        <LabelledControl label="Name">
                            <L10nFormControl
                                l10n={this.state.product.name || null}
                                language={this.state.currentLanguage || null}
                                onLocalizationChanged={l10n => {
                                    this.onLocalizationChanged("name", l10n)
                                }
                                }
                            />
                        </LabelledControl>


                        <LabelledControl label="Image (max size: 2048 × 2048)">
                            <ImageDropControl
                                fileName={pushid()}
                                filePath={this.props.role.account_id + "/public/products/" + this.state.product.id + "/images/"}
                                imageURL={this.state.product.image_url}
                                dropTitle="" //Max size: 2048×2048"
                                validateSize={(width: number, height: number) => {
                                    return width <= 2048 && height <= 2048
                                }}
                                isPublic={true}
                                deleteAction={() => { this.setProductImage(null) }}
                                uploadAction={(url) => { this.setProductImage(url) }}
                            />
                        </LabelledControl>

                        <LabelledControl label="Bar code">
                            <Form.Control
                                type="text"
                                name="barcode"
                                value={this.state.product.barcode || ""}
                                placeholder="Enter bar code"
                                onChange={this.handleProductInputChange}
                                autoComplete="off"
                            />

                        </LabelledControl>


                        <LabelledControl label="Short description">
                            <L10nFormControl
                                l10n={this.state.product.short_description || null}
                                type="textarea"
                                as="textarea"
                                language={this.state.currentLanguage || null}
                                style={{ resize: "vertical" }}
                                onLocalizationChanged={l10n => {
                                    this.onLocalizationChanged("short_description", l10n)
                                }}
                            />
                        </LabelledControl>

                        <LabelledControl label="Description">
                            <L10nFormControl
                                l10n={this.state.product.description || null}
                                type="textarea"
                                as="textarea"
                                language={this.state.currentLanguage || null}
                                style={{ resize: "vertical" }}
                                onLocalizationChanged={l10n => {
                                    this.onLocalizationChanged("description", l10n)
                                }}
                            />
                        </LabelledControl>
                    </Card.Body>
                </Card>

                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Pricing</Card.Title>

                        <LabelledControl label="Pricing mode">
                            <DropdownButton
                                variant="outline-primary"
                                size="sm"
                                title={this.pricingModeName()}
                                onSelect={(event: any) => { this.selectPricingMode(event) }}
                            >
                                <Dropdown.Item eventKey={"regular"}>Regular</Dropdown.Item>
                                <Dropdown.Item eventKey={"variable_price"}>Variable price</Dropdown.Item>
                                <Dropdown.Item eventKey={"unit_price"}>Unit price</Dropdown.Item>
                            </DropdownButton>
                        </LabelledControl>

                        {this.state.pricingMode === "regular" && <>

                            <LabelledControl label="Retail price">
                                <MarketAmountFormControl
                                    amount={this.state.product.retail_price ?? null}
                                    market={this.resolvedMarket()}
                                    allMarkets={Object.keys(this.state.metadata.markets)}
                                    onAmountChanged={amount => {
                                        this.onAmountChanged("retail_price", amount)
                                    }}
                                    placeholder="Enter Retail Price"
                                    currency={this.resolvedCurrency()}
                                />
                            </LabelledControl>

                            <LabelledControl label="Sale price">
                                <MarketAmountFormControl
                                    amount={this.state.product.sale_price ?? null}
                                    market={this.resolvedMarket()}
                                    allMarkets={Object.keys(this.state.metadata.markets)}
                                    onAmountChanged={amount => {
                                        this.onAmountChanged("sale_price", amount)
                                    }}
                                    placeholder="Enter Sale Price"
                                    currency={this.resolvedCurrency()}
                                />

                            </LabelledControl>

                            <LabelledControl label="Cost price">
                                <MarketAmountFormControl
                                    amount={this.state.product.cost_price ?? null}
                                    market={this.resolvedMarket()}
                                    allMarkets={Object.keys(this.state.metadata.markets)}
                                    onAmountChanged={amount => {
                                        this.onAmountChanged("cost_price", amount)
                                    }}
                                    placeholder="Enter Cost Price"
                                    currency={this.resolvedCurrency()}
                                />
                            </LabelledControl>

                        </>}

                        {this.state.pricingMode === "unit_price" && this.renderUnitPricing()}

                        <Card.Title>Taxes</Card.Title>

                        <LabelledControl>
                            <Dropdown onSelect={a => {
                                if (a !== null) {
                                    this.toggleTax(a)
                                }
                            }}>
                                <Dropdown.Toggle size="sm" variant="outline-primary">{this.selectedTaxesDescription()}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.taxes.map((tax) => {
                                        return <Dropdown.Item id={tax.id} active={this.selectedTaxes().includes(tax.id)} key={tax.id} eventKey={tax.id}>{tax.name} ({tax.rate * 100}%)</Dropdown.Item>
                                    })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>

                        </LabelledControl>
                    </Card.Body>
                </Card>

                {this.state.pricingMode === "regular" &&
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>Variants and dimensions</Card.Title>
                            <Card.Subtitle>Dimensions</Card.Subtitle>
                            {this.state.product.dimensions ?
                                (
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Values</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.product.dimensions.map((dimension, index) => {
                                                    const values = dimension.values || []
                                                    return (
                                                        <tr key={index} onClick={() => { this.editDimension(index) }}>
                                                            <td> {dimension.name.localized(this.state.currentLanguage || null)} </td>
                                                            <td> {values.map((value) => {
                                                                return value.name.localized(this.state.currentLanguage || null)
                                                            }).join(", ")}
                                                            </td>
                                                            <td style={{ width: 1, textAlign: "center" }}><Button variant="link" onClick={(event) => { this.removeDimension(index); event.stopPropagation() }}><DeleteButtonSymbol /></Button></td>
                                                        </tr>
                                                    )
                                                })}

                                        </tbody>
                                    </StripedTable>
                                )
                                : null
                            }
                            <Button variant="outline-primary" size="sm" onClick={() => { this.addDimension() }}>Add Variant Dimension</Button>
                            <br></br>
                            <br></br>
                            <Card.Subtitle>Variants</Card.Subtitle>
                            {this.state.product.variants ? (
                                <StripedTable>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.product.variants.map((variant, index) => {
                                                return (
                                                    <tr key={index} onClick={() => { this.editVariant(index) }}>
                                                        <td>{variant.id}</td>
                                                        <td>{productName(this.state.product, variant, this.state.currentLanguage || null)}</td>
                                                        <td style={{ width: 1, textAlign: "center" }}><Button variant="link" onClick={(event) => { this.removeVariant(index); event.stopPropagation() }}><DeleteButtonSymbol /></Button></td>
                                                    </tr>
                                                )
                                            })}

                                    </tbody>
                                </StripedTable>
                            )
                                : null
                            }
                            <Button variant="outline-primary" size="sm" onClick={() => { this.addVariant() }}>Add Variant</Button>
                            {((isNil(this.state.product.variants) || this.state.product.variants.length === 0) && (!isNil(this.state.product.dimensions) && this.state.product.dimensions.length > 0)) ? (
                                <div>
                                    <br />
                                    <Button variant="warning" onClick={() => { this.addVariantsForAllDimensions() }}>Create variants for all dimensions</Button>
                                </div>
                            )
                                : null}

                        </Card.Body>
                    </Card>
                }

                <AttributeSelection
                    attributeType="product"
                    account={this.props.role.account_id}
                    selectedAttributes={this.state.product.attributes || {}}
                    currentLanguage={this.state.currentLanguage}
                    onChange={(attributes) => { this.handleAttributesChange(attributes) }}
                />

                {
                    this.isProductRepo() &&
                    <Card className="pb-4">
                        <Card.Body>
                            <Card.Title>Product assets</Card.Title>

                            <Card.Subtitle>Gallery</Card.Subtitle>
                            <LabelledControl>
                                <Button variant="outline-primary" size="sm" onClick={() => { this.editGallery() }}>Edit image assets</Button>
                            </LabelledControl>

                            <Card.Subtitle>Video</Card.Subtitle>
                            <LabelledControl>
                                <Button variant="outline-primary" size="sm" onClick={() => { this.editVideoAssets() }}>Edit video assets</Button>
                            </LabelledControl>

                            <Card.Subtitle>Documents</Card.Subtitle>
                            <LabelledControl>
                                <Button variant="outline-primary" size="sm" onClick={() => { this.editDocumentAssets() }}>Edit document assets</Button>
                            </LabelledControl>
                        </Card.Body>
                    </Card>
                }

                {
                    this.isProductRepo() ? (
                        <>
                            <br /><br />
                            <ChannelSelector
                                selectedChannels={Object.keys(this.state.metadata.channels)}
                                onChange={channels => { this.handleChannelsChange(channels) }}
                            />
                        </>
                    ) : null
                }
            </div>
        </>
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="product"
                submit_action={async () => { await this.post() }}
                submit_disabled={this.submitDisabled()}
                discard_action={() => { this.pop() }}
            >
                <Form onSubmit={e => e.preventDefault()}>
                    <Container style={{ width: 950, marginLeft: "auto", marginRight: "auto" }}>
                        <Row>
                            <Col>
                                <Stack direction="horizontal" className="pb-2" gap={2}>
                                    <h4>{this.state.product.name?.localized(null)}</h4>

                                    <div className="ms-auto" />
                                    {this.isProductRepo() &&
                                        <MarketPicker
                                            role={this.props.role}
                                            currentMarket={this.state.currentMarket}
                                            resolveMarkets={() => { return Object.keys(this.state.metadata.markets) }}
                                            typeName="product"
                                            onMarketSelect={market => { this.handleCurrentMarketChange(market) }}
                                            addMarket={this.addMarket}
                                            removeMarket={this.removeMarket}
                                        />
                                    }
                                    <LanguagePicker
                                        typeName="product"
                                        initialLanguage={this.state.currentLanguage}
                                        resolveLanguages={() => { return this.resolveLanguages(this.state.product) }}
                                        onChange={this.setLanguage}
                                        onRemove={this.removeLanguage}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={8}>{this.renderContent()}</Col>
                            <Col md={12} lg={4}>

                                <Card><Card.Body>

                                    <LabelledControl label="Product group">
                                        <GroupSelection
                                            account={this.props.role.account_id}
                                            selectedGroup={this.state.product.product_group || null}
                                            currentLanguage={this.state.currentLanguage}
                                            onChange={(group) => { this.handleGroupChange(group ? group.group : null) }}
                                        />
                                    </LabelledControl>

                                    <LabelledControl label="Tags">
                                        <TagSelection
                                            account={this.props.role.account_id}
                                            selectedTags={this.state.product.tags || {}}
                                            currentLanguage={this.state.currentLanguage}
                                            onChange={(tags) => { this.handleTagsChange(tags) }}
                                        />
                                    </LabelledControl>

                                </Card.Body></Card>

                            </Col>
                        </Row>

                    </Container>

                </Form>

                {
                    this.state.dimensionEditIndex !== null ? (
                        <DimensionEdit
                            productId={this.state.product.id}
                            editedDimension={this.editedDimension()}
                            role={this.props.role}
                            setLanguage={this.setLanguage}
                            currentLanguage={() => { return this.state.currentLanguage || null }}
                            resolveLanguages={() => { return this.resolveLanguages(this.state.product) }}
                            cancelDialog={this.closeDialog}
                            publishDialog={(dimension) => {
                                this.publishDimension(dimension)
                                this.closeDialog()
                            }}
                        />
                    ) : null
                }

                {
                    this.state.variantEditIndex !== null ? (
                        <VariantEdit
                            role={this.props.role}
                            editedVariant={this.editedVariant()}
                            product={() => { return this.state.product }}
                            setLanguage={this.setLanguage}
                            currentLanguage={() => { return this.state.currentLanguage }}
                            resolveLanguages={() => { return this.resolveLanguages(this.state.product) }}
                            handleCurrentMarketChange={market => { this.handleCurrentMarketChange(market) }}
                            currentMarket={this.resolvedMarket()}
                            markets={Object.keys(this.state.metadata.markets)}
                            currency={this.resolvedCurrency()}
                            cancelDialog={this.closeDialog}
                            publishDialog={(variant) => {
                                this.publishVariant(variant)
                                this.closeDialog()
                            }}
                            addMarket={market => { this.addMarket(market) }}
                            removeMarket={market => { this.removeMarket(market) }}
                            isProductRepo={() => { return this.isProductRepo() }}
                        />
                    ) : null
                }

                {
                    this.state.galleryEdit ? (
                        <ProductGalleryEdit
                            assets={() => { return this.state.imageAssets }}
                            role={this.props.role}
                            closeDialog={this.closeDialog}
                            productId={this.state.product.id}
                            updateState={(update) => { this.setState({ imageAssets: update }); this.setState({ dirty: true }) }}
                        />
                    ) : null
                }

                {
                    this.state.videoAssetsEdit ? (
                        <ProductVideoAssetsEdit
                            assets={() => { return this.state.videoAssets }}
                            role={this.props.role}
                            closeDialog={this.closeDialog}
                            updateAssets={(videoAssets) => { this.setState({ videoAssets: videoAssets }); this.setState({ dirty: true }) }}
                        />
                    ) : null
                }

                {
                    this.state.documentAssetsEdit
                        ? (
                            <ProductDocumentAssetsEdit
                                assets={() => { return this.state.documentAssets }}
                                role={this.props.role}
                                setLanguage={this.setLanguage}
                                currentLanguage={() => { return this.state.currentLanguage }}
                                resolveLanguages={() => { return this.resolveLanguages(this.state.product) }}
                                closeDialog={this.closeDialog}
                                productId={this.state.product.id}
                                updateAssets={(assets) => { this.setState({ documentAssets: assets }); this.setState({ dirty: true }) }}
                            />
                        )
                        :
                        null
                }

                {
                    this.state.showAlertForMissingMarket ?
                        (
                            <Modal show={true} key="b">
                                <Modal.Header>
                                    <Modal.Title>Invalid data</Modal.Title>
                                </Modal.Header>

                                <Modal.Footer>
                                    <Button variant="danger" onClick={() => { this.pop() }}>OK</Button>
                                </Modal.Footer>
                            </Modal>
                        )
                        :
                        null
                }

            </PageState >
        )
    }
}

export default withRoleRouter(ProductEdit)
