import React from "react"
import { Form } from "react-bootstrap"
import { AttributeObserver } from "../../../../helpers/attributeObserver";
import { ProductObserver } from "../../../../helpers/productObserver";
import { TagObserver } from "../../../../helpers/tagObserver";
import { Market } from "../../../../models/MarketModels";
import { AppliesTo, AppliesToSelectorCard } from "../AppliesToSelector";
import { CustomerCondition, CustomerConditionSelector } from "../CustomerConditionSelector";
import { DiscountSelector, DiscountType } from "../DiscountSelector";
import { MinimumRequirementsSelector, RequirementsType } from "../MinimumRequirementsSelector";
import { Stair } from "../Stair";
import { RowData } from "../StairStep";
import { Description } from "./CustomizableFormDescription";
import * as _ from "lodash"
import { DateComponents, RuleModel, FormType, CouponTemplate, CouponValues, Expiration, isFixedExpiration } from "../../../../models/RuleModels";
import { L10nString, LanguageCode } from "../../../../helpers/L10n";
import { CustomizableTemplate } from "./Model";
import { SharedPropertiesSelector } from "../SharedProperties";
import { AdvancedPropertiesSelector } from "../AdvancedProperties";
import { CouponTemplateSelector } from "../CouponTemplateSelector";
import { updateCouponValues, updateExpiration } from "../Shared/Helpers";

export interface CustomizableFormProps {
    market: Market | null
    formType: FormType
    markets: string[]
    currentLanguage: () => LanguageCode | null
    productObserver: ProductObserver
    tagObserver: TagObserver
    attributeObserver: AttributeObserver
    customerAttributeObserver: AttributeObserver
    template: CustomizableTemplate
    allShops: _.Dictionary<string>
    showId: boolean
    isCoupon: boolean
    couponTemplates?: CouponTemplate[]
    onTemplateChanged: (template: RuleModel) => void
}

export function CustomizableForm(props: CustomizableFormProps) {

    function setDisplayName(name?: L10nString) {
        if (_.isEqual(props.template.display_name, name)) {
            return
        }
        const clone = _.clone(props.template)
        clone.display_name = name
        props.onTemplateChanged(clone)
    }

    function setDiscountType(type: DiscountType) {
        if (props.template.discountType === type) {
            return
        }
        const clone = _.clone(props.template)        
        clone.discountType = type
        if (props.formType === "loyalty_points" && clone.appliesTo.type === "all") {
            clone.appliesTo.type = "basket"
        } 
        if (props.formType === "loyalty_points" && type === "amount_off" && clone.appliesTo.type === "basket" ) {
            clone.appliesTo.type = "products"
        }
        props.onTemplateChanged(clone)
    }

    function setRequirementsType(type: RequirementsType) {
        if (props.template.requirementsType === type) {
            return
        }
        const clone = _.clone(props.template)
        clone.requirementsType = type
        props.onTemplateChanged(clone)
    }

    function setAppliesTo(appliesTo: AppliesTo) {
        if (_.isEqual(props.template.appliesTo, appliesTo)) {
            return
        }
        const clone = _.clone(props.template)
        clone.appliesTo = appliesTo
        props.onTemplateChanged(clone)
    }

    function setSteps(steps: RowData[]) {
        if (_.isEqual(props.template.steps, steps)) {
            return
        }
        const clone = _.clone(props.template)
        clone.steps = steps
        props.onTemplateChanged(clone)
    }

    function setCustomerCondition(condition: CustomerCondition) {
        if (_.isEqual(props.template.customerCondition, condition)) {
            return
        }
        const clone = _.clone(props.template)
        clone.customerCondition = condition
        props.onTemplateChanged(clone)
    }

    function setPriority(priority: number | undefined) {
        if (props.template.priority === priority) {
            return
        }
        const clone = _.clone(props.template)
        clone.priority = priority
        props.onTemplateChanged(clone)
    }

    function setAdvancedProps(startDate?: DateComponents, endDate?: DateComponents, continueEvaluation?: boolean, shopIds?: string[]) {
        const clone = _.clone(props.template)
        clone.shop_ids = shopIds
        clone.start_date = startDate
        clone.end_date = endDate
        clone.continue_evaluation = continueEvaluation
        if (_.isEqual(clone, props.template)) {
            return
        }        
        props.onTemplateChanged(clone)
    }

    function setSelectedCouponTemplate(couponTemplate: CouponTemplate | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('template', couponTemplate, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponTemplateTitle(couponTitle: L10nString | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('title', couponTitle, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponSubtitle(subtitle: L10nString | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('subtitle', subtitle, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponExpiration(expiration: Expiration | undefined) {
        const clone = _.clone(props.template)
        const updatedClone = updateExpiration(clone, expiration)
        props.onTemplateChanged(updatedClone)
    }

    return <Form onSubmit={e => e.preventDefault()}>
        <SharedPropertiesSelector validation={props.template.validateShared()} currentLanguage={props.currentLanguage} displayName={props.template.display_name} priority={props.template.priority} updateSharedProps={(displayName, priority) => { setDisplayName(displayName); setPriority(priority) }} />
        {props.isCoupon === true ? 
            <CouponTemplateSelector 
            currentLanguage={props.currentLanguage} 
            couponTemplates={props.couponTemplates} 
            title={props.template.coupon?.title} 
            subtitle={props.template.coupon?.subtitle}  
            selectedCoupon={props.template.coupon?.template}
            expiration={props.template.coupon?.expiration}
            templateChanged={(selectedCouponTemplate) => {setSelectedCouponTemplate(selectedCouponTemplate)}}
            titleChanged={(title) => {setCouponTemplateTitle(title) }}
            subtitleChanged={(subtitle) => {setCouponSubtitle(subtitle) }}
            expirationChanged={expiration => {setCouponExpiration(expiration)}}
            ></CouponTemplateSelector> : null}
        <DiscountSelector markets={props.markets} validation={true} type={props.template.discountType} formType={props.formType} typeChanged={(type) => { setDiscountType(type) }} />
        <MinimumRequirementsSelector type={props.template.requirementsType} typeChanged={(type) => { setRequirementsType(type) }} />
        <Stair markets={props.markets} validation={props.template.validateSteps()} discountType={props.template.discountType} formType={props.formType} requirementsType={props.template.requirementsType} market={props.market} rows={props.template.steps} rowsChanged={(rows) => { setSteps(rows) }} />
        <AppliesToSelectorCard showId={props.showId} validation={props.template.validateAppliesTo()} isCoupon={props.isCoupon} loyaltyPointMode={props.formType === "discount" ? undefined : (props.template.discountType === "percentage" ? "basket_based" : "item_based")} productObserver={props.productObserver} tagsObserver={props.tagObserver} attributesObserver={props.attributeObserver} appliesTo={props.template.appliesTo} appliesToChanged={(appliesTo) => { setAppliesTo(appliesTo) }} />
        <CustomerConditionSelector validation={props.template.validateCustomerCondition()} attributesObserver={props.customerAttributeObserver} condition={props.template.customerCondition} conditionChanged={(condition) => { setCustomerCondition(condition) }} />
        <AdvancedPropertiesSelector allShops={props.allShops} isCoupon={props.isCoupon} selectedShops={props.template.shop_ids} continueEvaluation={props.template.continue_evaluation} startDate={props.template.start_date} endDate={props.template.end_date} updateAdvancedProps={(startDate, endDate, continueEvaluation, shopIds) => { setAdvancedProps(startDate, endDate, continueEvaluation, shopIds) }} />
        <Description allShops={props.allShops} validation={props.template.valid([])} formProps={props} formType={props.formType} customerCondition={props.template.customerCondition} market={props.market} discountType={props.template.discountType} requirementsType={props.template.requirementsType} steps={props.template.steps} appliesTo={props.template.appliesTo} />
    </Form>
}


