//
//  GroupSelection.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 13/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import firebase from "firebase/compat"
import { ref } from "../config/constants"
import { forEach } from "lodash"
import { ProductGroup } from "../models/Product"
import { L10nString, LanguageCode } from "../helpers/L10n"
import { Dropdown } from "react-bootstrap"

interface GroupSelectionProps {
    account: string
    selectedGroup: string | null
    currentLanguage: LanguageCode | null
    onChange: (group: ProductGroup | null) => void
}

interface GroupSelectionState {
    groups: ProductGroup[]
}

export class GroupSelection extends React.Component<GroupSelectionProps, GroupSelectionState> {

    // Properties

    initialState: GroupSelectionState = {
        groups: []
    }

    // Component

    constructor(props: GroupSelectionProps) {
        super(props)

        this.state = this.initialState
    }
    async componentDidMount() {
        const snapshot = await this.groupsRef().once("value")

        if (!snapshot || (snapshot && !snapshot.exists())) {
            this.setState({ groups: [] })
            return
        }

        const groups: ProductGroup[] = []
        forEach(snapshot.val(), (value) => {
            groups.push(new ProductGroup(value))
        })

        const sorted = groups.sort((lhs, rhs) => {
            return lhs.name.localized(this.props.currentLanguage) > rhs.name.localized(this.props.currentLanguage) ? -1 : 1
        })

        this.setState({
            groups: [new ProductGroup({ group: null, name: new L10nString("No product group") })].concat(sorted)
        })

    }

    componentWillUnmount() {
        this.groupsRef().off()
    }

    render() {
        return (
            <>
                {
                    this.state.groups ? (
                        <Dropdown
                            onSelect={a => {
                                const group = this.state.groups.find(g => { return g.group === a })
                                this.props.onChange(group ?? null)
                            }}
                        >
                            <Dropdown.Toggle variant="outline-primary" size="sm">
                                {this.props.selectedGroup ? (this.state.groups.find(g => { return g.group === this.props.selectedGroup })?.name.localized(null) ?? this.props.selectedGroup) : "Select a product group"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.groups.map((group) => {
                                    return <Dropdown.Item active={group.group === this.props.selectedGroup} id={group.group} key={group.group} eventKey={group.group}>{group.name.localized(this.props.currentLanguage)}</Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : <div>Loading..</div>
                }
            </>
        )
    }

    groupsRef(): firebase.database.Reference {
        return ref().child(`v1/accounts/${this.props.account}`).child("inventory/product_groups")
    }
}
