import React, { useState, useEffect } from "react"
import { Button, FormControl, InputGroup, Modal } from "../../wrappers"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { LanguageCode } from "../../../helpers/L10n"
import { ProductObserver } from "../../../helpers/productObserver"
import { TagObserver } from "../../../helpers/tagObserver"
import { Market } from "../../../models/MarketModels"
import { AppliesTo, AppliesToSelector, DeleteButtonSymbol } from "./AppliesToSelector"

interface BundleStairStepProps {
    market: Market | null
    row: BundleRowData
    rowChanged: (row: BundleRowData) => void
    onRowDeleted?: (row: BundleRowData) => void
    productObserver: ProductObserver
    tagsObserver: TagObserver
    attributesObserver: AttributeObserver
    showId: boolean
}

export interface BundleRowData {
    id: number
    count?: number
    appliesTo: AppliesTo
}

function rowWithCount(row: BundleRowData, event: any): BundleRowData {
    const value = event.target.value
    const count = Number(value)
    return {
        id: row.id,
        count: value === "" ? undefined : count,
        appliesTo: row.appliesTo
    }
}

function rowWithAppliesTo(row: BundleRowData, appliesTo: AppliesTo): BundleRowData {
    return {
        id: row.id,
        count: row.count,
        appliesTo: appliesTo
    }
}

function appliesToDescription(appliesTo: AppliesTo, productObserver: ProductObserver, tagObserver: TagObserver, attributeObserver: AttributeObserver): string {
    const tagNameForId = (id: string): string | null => {
        if (!tagObserver.tagsDict) {
            return id;
        }
        const tag = tagObserver.tagsDict[id];
        return tag?.name.localized(LanguageCode.da) ?? id
    };
    const productNameForId = (id: string): string | null => {
        if (!productObserver.productsDict) {
            return null;
        }
        const product = productObserver.productsDict[id];
        return product?.localizedName(LanguageCode.da) ?? null
    };
    let matchString: string = "";
    // const descriptionStrings: string[] = []
    if (appliesTo.type === "all") {
        matchString = "of any kind";
    } else if (appliesTo.type === "tags") {
        const list = appliesTo.tags.map(tagId => {
            return tagNameForId(tagId);
        });

        if (list.length === 1) {
            matchString = `that have the tag '${list[0]}'`;
        } else {
            matchString = `that have any of the tags '${list.join(", ")}'`;
        }

    } else if (appliesTo.type === "products") {

        const productNames = appliesTo.products.map(productId => {
            return productNameForId(productId) || productId;
        });
        if (productNames.length === 0) {
            return "(please select one or more products)";
        }
        else if (productNames.length === 1) {
            matchString = `of type ${productNames[0]}`;
        } else {
            matchString = `in the list: [${productNames.join(", ")}]`;
        }
    } else if (appliesTo.type === "attributes") {
        const attributes = appliesTo.attributes;
        const attributeDescriptions = attributes.map((attributeSelection) => {
            const attribute = attributeObserver.attributesDict?.[attributeSelection.attributeId];
            if (attribute === undefined) {
                return "";
            }
            let value = attributeSelection.optionId
            const option = attribute.type.options?.[attributeSelection.optionId];
            if (option !== undefined) {
                value = option.name.localized(LanguageCode.da)
            }

            return `${attribute.name.localized(LanguageCode.da)}: ${value}`;
        });
        if (attributeDescriptions.length === 1) {
            matchString = `with the attribute value: ${attributeDescriptions[0]}`;
        } else {
            matchString = `with any of the attribute values in the list: [${attributeDescriptions.join(", ")}]`;
        }
    }

    return `Products ${matchString}`
}

export function BundleStairStep(props: BundleStairStepProps) {
    const [row, setRow] = useState<BundleRowData>(props.row);
    const [edit, setEdit] = useState<boolean>(false);
    useEffect(() => {
        props.rowChanged(row)
    }, [row, props])

    return <tr>
        <td key="a" style={{ width: "1%", whiteSpace: "nowrap" }}>Required item count:</td>
        <td key="b">
            <InputGroup>
                <FormControl
                    type="number"
                    name="count"
                    // min={0}
                    step={1}
                    value={row.count ?? ""}
                    placeholder="Enter quantity"
                    onChange={(event: any) => { setRow(rowWithCount(row, event)) }}
                />
            </InputGroup>
        </td>
        <td key="e" /*style={{ width: "1%", whiteSpace: "nowrap" }}*/>
            Required products: <b>
                {appliesToDescription(row.appliesTo, props.productObserver, props.tagsObserver, props.attributesObserver)}
            </b><br />
            {
                edit ? (
                    <Modal show={true}>
                        <Modal.Header>
                            <Modal.Title>Product selection</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <AppliesToSelector
                                bundle={true}
                                isCoupon={false}
                                appliesTo={row.appliesTo}
                                appliesToChanged={(appliesTo) => {
                                    setRow(rowWithAppliesTo(row, appliesTo))
                                }}
                                productObserver={props.productObserver}
                                tagsObserver={props.tagsObserver}
                                attributesObserver={props.attributesObserver}
                                showId={props.showId}
                                validation={true} />
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="primary" onClick={() => { setEdit(false) }}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                ) : (
                    <Button variant="primary" onClick={() => { setEdit(true) }}>Change</Button>
                )
            }

        </td>

        {props.onRowDeleted && <td><Button variant="link" onClick={() => { props.onRowDeleted?.(row) }}><DeleteButtonSymbol /></Button></td>}
    </tr >
}

