import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import React, { useState } from "react";
import FormLabel from "react-bootstrap/esm/FormLabel";
import { DateRange, RangeFocus } from "react-date-range";
import { L10nFormControl } from "src/components/L10nFormControl";
import { Card, Col, DropdownButton, FormControl, FormGroup, HelpBlock, MenuItem, Row } from "src/components/wrappers";
import { L10nString, LanguageCode } from "src/helpers/L10n";
import { CouponTemplate, DateComponent, DateComponents, Expiration, FixedExpiration, RelativeExpiration, isFixedExpiration } from "src/models/RuleModels";
import { dayjsFromDateComponents } from "./RuleTemplateForm";

interface CouponTemplateSelectorProps {
    couponTemplates?: CouponTemplate[]
    selectedCoupon?: CouponTemplate
    title?: L10nString
    subtitle?: L10nString
    expiration?: Expiration
    currentLanguage: () => LanguageCode | null;
    templateChanged: (template?: CouponTemplate) => void
    titleChanged: (title?: L10nString) => void
    subtitleChanged: (subtitle?: L10nString) => void
    expirationChanged: (expiration?: Expiration) => void
}

enum ExpirationType {
    fixedExpiration = "Fixed Expiration",
    relativeExpiration = "Relative Expiration"
}

export function CouponTemplateSelector(props: CouponTemplateSelectorProps) {
    const [expirationType, setExpirationType] = useState<ExpirationType>(getInitialExpirationType(props.expiration));
    const [focusedInput, setFocusedInput] = useState<RangeFocus>([0,0]);
    return <Card className="my-4" border="primary">
            <Card.Header>
                Coupon values
            </Card.Header>
            <Card.Body>
                <FormGroup className="mb-3" as={Row}> 
                    <Col sm={2}>Template</Col>
                    <Col sm={10}>{renderCouponTemplateSelectionButton(props) }</Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row} >
                <Col sm={2}><FormLabel>Title</FormLabel></Col>
                <Col sm={10}>{ 
                    <L10nFormControl
                        l10n={props.title ?? null}
                        type="text"
                        language={props.currentLanguage()}
                        placeholder="Enter a title for the coupon"
                        style={{ resize: "vertical" }}
                        onLocalizationChanged={(l10n) => { props.titleChanged(!_.isNil(l10n) ? l10n : undefined); }} /> }
                        </Col>
                    
                </FormGroup>
                <FormGroup className="mb-3" as={Row} >
                <Col sm={2}><FormLabel>Subtitle</FormLabel></Col>
                <Col sm={10}>{ 
                    <L10nFormControl
                        l10n={props.subtitle ?? null}
                        type="text"
                        language={props.currentLanguage()}
                        placeholder="Enter a subtitle for the coupon"
                        style={{ resize: "vertical" }}
                        onLocalizationChanged={(l10n) => { props.subtitleChanged(!_.isNil(l10n) ? l10n : undefined); }} /> }
                        </Col>
                    
                </FormGroup>
                {expirationTypeSelector(props, expirationType, setExpirationType)}
                {expirationType == ExpirationType.fixedExpiration ? fixedExpirationDatePicker(props, focusedInput, setFocusedInput) : relativeExpiration(props)}
            </Card.Body>
        </Card>;
}

function expirationTypeSelector(props: CouponTemplateSelectorProps, expirationType: ExpirationType, setExpirationType: React.Dispatch<React.SetStateAction<ExpirationType>>) {
    return <FormGroup className="mb-3" as={Row}>
        <Col sm={2}>Expiration type</Col>
        <Col sm={10}>
            <DropdownButton
                title={expirationType}
                id="dropdown-add-coupon-template"
                onSelect={(type: any) => {
                    if (type !== expirationType) {
                        props.expirationChanged(undefined)
                    }
                    setExpirationType(type);
                } }
            >
                {[ExpirationType.fixedExpiration, ExpirationType.relativeExpiration].map((value) => {
                    return (<MenuItem key={value} eventKey={value}>{value}</MenuItem>);
                })}
            </DropdownButton>
        </Col>
    </FormGroup>;
}

function relativeExpiration(props: CouponTemplateSelectorProps) {
    const dateComponents: DateComponent[] = ['day', 'month', 'year']
    const relativeExpiration: RelativeExpiration = getRelativeExpiration(props)

    return <div>
        <FormGroup className="mb-3" as={Row}>
            <Col sm={2}></Col>
            <Col sm={10}> 
                <DropdownButton
                    title={getDateComponentTitle(relativeExpiration?.date_component)}
                    id="dropdown-add-coupon-template"
                    onSelect={(dateComponent: any) => {
                        const dateComponentSelected = (dateComponent as DateComponent)
                        const expiration = relativeExpiration
                        relativeExpiration.date_component = dateComponentSelected
                        props.expirationChanged(expiration);
                    } 
                }
                >
                    {dateComponents.map((dateComponent) => {
                        return (<MenuItem key={dateComponent} eventKey={dateComponent}>{getDateComponentTitle(dateComponent)}</MenuItem>);
                    })}
                </DropdownButton>
            </Col>
        </FormGroup>
        { !_.isNil(relativeExpiration.date_component) ?
            <div>
            <FormGroup className="mb-3" as={Row}>
                <Col sm={2}>  </Col>
                <Col sm={10}>
                <FormControl
                            type="number"
                            name="count"
                            min={0}
                            //step={10}
                            value={relativeExpiration.count ?? ""}
                            placeholder={`Number of ${getDateComponentPluralTitle(relativeExpiration.date_component)}`}
                            onChange={(e: any) => { 
                                const countSelected = Number(e.target.value)
                                let expiration = relativeExpiration
                                expiration.count = countSelected.valueOf()
                                props.expirationChanged(expiration); 
                            }} />
                { !_.isNil(relativeExpiration.count) ? <HelpBlock style={{paddingLeft: 10, paddingTop: 5, fontSize: 18}}> The expiration date will be {relativeExpiration.count} {getDateComponentPluralTitle(relativeExpiration.date_component)} from the issued date.</HelpBlock> : null }
                </Col>
            </FormGroup> 
            </div>
            : null
        }
    
    </div>

}

function getRelativeExpiration(props: CouponTemplateSelectorProps): RelativeExpiration {
    let asRelative: RelativeExpiration = (props.expiration as RelativeExpiration) ?? {}
    let expiration: RelativeExpiration = {
        count: asRelative.count,
        date_component: asRelative.date_component
    }
    return expiration
}

function getDateComponentTitle(dateComponent?: DateComponent): string {
    if (_.isNil(dateComponent)) {
        return "Select date component"
    }
    switch (dateComponent) {
        case 'day': return "Day"
        case 'month': return "Month"
        case 'year': return "Year"
    }
}

function getDateComponentPluralTitle(dateComponent: DateComponent): string {
    switch (dateComponent) {
        case 'day': return "days"
        case 'month': return "months"
        case 'year': return "years"
    }
}

function fixedExpirationDatePicker(props: CouponTemplateSelectorProps, focusedInput: RangeFocus, setFocusedInput: React.Dispatch<React.SetStateAction<RangeFocus>>) {
    return <FormGroup className="mb-3" as={Row}>
        <Col sm={2}></Col>
        <Col sm={10}>
            <DateRange onRangeFocusChange={rangeFocus => setFocusedInput(rangeFocus)} className={focusedInput === null ? "collapsed" : ""} editableDateInputs={true}
                onChange={item => {
                    const range = item["selection"];
                    if (!range) { return; }
                    const start = dayjs(range.startDate).startOf("day");
                    const end = dayjs(range.endDate).endOf("day");
                    const startDate: DateComponents = {
                        day: start.date(),
                        month: start.month() + 1,
                        year: start.year()
                    };
                    const endDate: DateComponents = {
                        day: end.date(),
                        month: end.month() + 1,
                        year: end.year()
                    };
                    const fixedExpiration: FixedExpiration = {
                        start_date: startDate,
                        end_date: endDate
                    };
                    props.expirationChanged(fixedExpiration)
                } }
                moveRangeOnFirstSelection={true}
                ranges={[{
                    startDate: (props.expiration as FixedExpiration)?.start_date ? dayjsFromDateComponents((props.expiration as FixedExpiration).start_date).toDate() : undefined,
                    endDate: (props.expiration as FixedExpiration)?.end_date ? dayjsFromDateComponents((props.expiration as FixedExpiration).end_date).toDate() : undefined,
                    key: "selection"
                }]} />
        </Col>
    </FormGroup>;
}

function renderCouponTemplateSelectionButton(props: CouponTemplateSelectorProps) {
    const title = props.selectedCoupon?.name ?? "Select coupon template"
    if (!_.isNil(props.couponTemplates) && props.couponTemplates.length > 0) {
        return (
            <DropdownButton
                title= { title }
                id="dropdown-add-coupon-template"
                onSelect={(selectedAttribute: any) => {
                    selectCouponTemplate(props, selectedAttribute)
                }}
            >
                {props.couponTemplates.map((value) => {
                    return (<MenuItem key={value.identifier} eventKey={value.identifier}>{value.name}</MenuItem>)
                })}
            </DropdownButton>
        )
    } else {
        return <div style={{"color": "red"}}> Warning: You must setup coupon templates before you can create coupon discounts. </div>
    }
}

function selectCouponTemplate(props: CouponTemplateSelectorProps, selectedAttribute: any) {
    const availableCoupons = props.couponTemplates
    const selectedCoupon = availableCoupons?.find(value => {
        return value.identifier === selectedAttribute
    })
    if (!_.isNil(selectedCoupon)) {
        props.templateChanged(selectedCoupon)
    }
}

function getInitialExpirationType(expiration?: Expiration): ExpirationType {
    if (_.isNil(expiration)) { 
        return ExpirationType.relativeExpiration
    }
    if (isFixedExpiration(expiration)) {
       return ExpirationType.fixedExpiration 
    } else {
       return ExpirationType.relativeExpiration
    }
}