import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { RoleRouterProps, withRoleRouterFunc } from "src/routes";
import { PDFTemplate } from "src/models/pdf_template/pdf_template";
import { PDFTemplateLine, PDFTemplateLineType } from "src/models/pdf_template/pdf_template_line";
import { Card, Form } from "react-bootstrap";
import '../styles.css';
import _, { update } from "lodash";
import { PageState } from "src/components/PageState";
import { DragDropContext, DraggableProvided, Droppable } from "react-beautiful-dnd";
import { ComponentEdit } from "./ComponentEdit/ComponentEdit";
import { Button, Col, FormGroup, Row } from "src/components/wrappers";
import { AddLineItem } from "./AddLineItem";
import { TextLineItem } from "./LineItems/TextLineItem";
import { SpacingLineItem } from "./LineItems/SpacingLineItem";
import { BarcodeLineItem } from "./LineItems/BarcodeLineItem";
import { WalletLineItem } from "./LineItems/WalletLineItem";
import { ImageLineItem } from "./LineItems/ImageLineItem";
import { GlobalParameters } from "./GlobalParameters";
import { DraggableWrapper } from "../DraggableWrapper";

function GiftcardPDFTemplateEditor(props: RoleRouterProps) {
    const accountId = props.role.account_id
    const typeId = props.router.params.typeId
    const [pdfTemplate, setPDFTemplate] = useState<PDFTemplate>()
    const [isModalShowing, setIsShowingModal] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [selectedLineItem, setSelectedLineItem] = useState<PDFTemplateLine>()
    const [isPublishing, setIsPublishing] = useState<boolean>(false)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [customFonts, setCustomFonts] = useState<string[]>([])
    const [isNewLineShowing, setIsNewLineShowing] = useState<boolean>(false)
    const [isShowingGlobalValues, setIsShowingGlobalValues] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    
    useEffect(() => {
        fetchTemplatePromise(accountId, typeId).then(value => {
            const data = (value.data as any)
            const pdfTemplate = PDFTemplate.fromJSON(data)
            console.log(pdfTemplate)
            setPDFTemplate(pdfTemplate)
            const customFonts = Array.from(pdfTemplate.fontMap.keys()).sort()
            setCustomFonts(customFonts)
            setIsLoading(false)
        }).catch(() => {
            const template = new PDFTemplate([])
            setPDFTemplate(template)
        }).finally(() => setIsLoading(false))
    }, [accountId, typeId])

    return (
        <PageState loading={isLoading} publishing={isPublishing} dirty={isDirty} typeName={""} submit_action={() => submit(accountId, typeId, pdfTemplate?.toJSON())}>
            <Card>
            <Card.Header> 
                <Row>
                    <Col>PDF template editor</Col> 
                    <Col><Button onClick={() => (setIsShowingGlobalValues(!isShowingGlobalValues))} style={{float: "right"}}> {setGlobalValueButtonTitle()}</Button></Col>
                </Row>
            </Card.Header>
            <div>
            <div className="flex-container">
                
             {!_.isNil(pdfTemplate) ?
             <Form className="form">
                {isShowingGlobalValues ?
                <GlobalParameters pdfTemplate={pdfTemplate} customFonts={customFonts} updateTemplate={updateTemplate } ></GlobalParameters>
                : <div></div>
                }
                <br></br>
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={4}>
                        <div className="globalLabel"> Lines:</div>
                    </Col>
                    <Col sm={8}>
                        <Button onClick={() => { setIsNewLineShowing(true) }} style={{float: "right"}}>Add line</Button>
                    </Col>
                </FormGroup>
    
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable"> 
                        {(droppableProvided) => (
                            <div className="listContainer" ref={droppableProvided.innerRef}>
                                {createPDFComponents(pdfTemplate.lines)}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                </Droppable>
                </DragDropContext>
            </Form>
            : <div></div>}
            { showModal(selectedIndex, selectedLineItem)}
            {isNewLineShowing ? <AddLineItem onCancel={() => setIsNewLineShowing(false)} onOK={(lineItem) => { addNewLineItem(lineItem) } } customFonts={customFonts} ></AddLineItem> : <div></div>}
            <div className="preview-container">
                    <div className="preview">
                        <div style={{fontWeight:"bold"}}>PDF preview coming soon!</div>
                    </div>
                </div>
            </div>
            </div>
            </Card>
        </PageState>
    )

    function addNewLineItem(lineItem: PDFTemplateLine) {
        setIsNewLineShowing(false);
        updateTemplate((template) => {
            const lines = [lineItem]
            lines.push(..._.cloneDeep(template.lines))
            template.lines = lines
            return template
        })
    }

    function updateTemplate(callback: (pdfTemplate: PDFTemplate) => PDFTemplate) {
        if (_.isNil(pdfTemplate)) { return }
        let updatedTemplate = _.cloneDeep(pdfTemplate);
        updatedTemplate = callback(updatedTemplate);
        setPDFTemplate(updatedTemplate);
        console.log(updatedTemplate)
        const customFonts = Array.from(updatedTemplate.fontMap.keys()).sort()
        setCustomFonts(customFonts)
        setIsDirty(true);
    }

    function setGlobalValueButtonTitle() {
        if (isShowingGlobalValues) { return "Show only PDF lines"}
        else { return "Show all values"}
    }

    function onDragEnd(result: any) {
        // dropped outside the list
        if (!result.destination || result.destination.index === result.source.index) {
            return
        }
    
        // no movement
        if (result.destination.index === result.source.index) {
            return
        }

        const updatedTemplate = _.cloneDeep(pdfTemplate)

        if (_.isNil(updatedTemplate)) { return }
    
        let newOrdering = _.cloneDeep(updatedTemplate.lines)
        const [removed] = newOrdering.splice(result.source.index, 1)
        newOrdering.splice(result.destination.index, 0, removed)

        updatedTemplate.lines = newOrdering

        setPDFTemplate(updatedTemplate)
        setIsDirty(true)
    }


    function createPDFComponents(pdfTemplateLines: PDFTemplateLine[]): React.ReactNode {
        let array: JSX.Element[] = []

        for (const [index, lineItem] of pdfTemplateLines.entries()) {
            let component: JSX.Element
            switch (lineItem.lineType) {
                case PDFTemplateLineType.text:
                    component = <TextLineItem lineItem={lineItem} onDelete={() => {onLineItemDelete(index)}}></TextLineItem>
                    break
                case PDFTemplateLineType.spacer: 
                    component = <SpacingLineItem lineItem={lineItem} onDelete={() => {onLineItemDelete(index)}}></SpacingLineItem>
                    break
                case PDFTemplateLineType.barcode: 
                    component = <BarcodeLineItem lineItem={lineItem} onDelete={() => {onLineItemDelete(index)}}></BarcodeLineItem>
                    break
                case PDFTemplateLineType.wallet_url: 
                    component = <WalletLineItem lineItem={lineItem} onDelete={() => {onLineItemDelete(index)}}></WalletLineItem>
                    break
                case PDFTemplateLineType.image: 
                    component = <ImageLineItem lineItem={lineItem} onDelete={() => {onLineItemDelete(index)}}></ImageLineItem>
                    break
            }

            let wrapper = <DraggableWrapper key={index} index={index} onClick={() => { setupModalData(index, lineItem)  }} component={component} />

            array.push(wrapper)
        }

        return array
    }

    function setupModalData(index: number, lineItem: PDFTemplateLine) {
        setSelectedIndex(index)
        setSelectedLineItem(lineItem)
        setIsShowingModal(true)
    }

    function onLineItemDelete(index: number) {
        updateTemplate((template) => {
            const lines = _.cloneDeep(template.lines)
            lines.splice(index, 1)
            const updatedTemplate = _.cloneDeep(template)
            updatedTemplate.lines = lines
            return updatedTemplate
        })
    }

    function showModal(index: number | undefined, lineItem: PDFTemplateLine | undefined) {
        if (!_.isNil(index) && ! _.isNil(lineItem) && isModalShowing) {
            return <ComponentEdit customFonts={customFonts} index={index} lineItem={lineItem} onOK={(index, lineItem) => {
                const updatedTemplate = _.cloneDeep(pdfTemplate)
                if (!_.isNil(updatedTemplate)) {
                    updatedTemplate.lines[index] = lineItem
                    setPDFTemplate(updatedTemplate)
                    setIsDirty(true)
                }
                setIsShowingModal(false)
            }} onCancel={() => setIsShowingModal(false)}></ComponentEdit>
        } else {
            return <div></div>
        }
    }

    async function submit(accountId: string, type: string, templateData: any) {
        const args: any = {
            action: "giftcard-template-create",
            account: accountId,
            type: type,
            data: templateData,
            template: "pdf"
        };
        setIsPublishing(true)
        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
        setIsPublishing(false)
        setIsDirty(false)
    }
}

function fetchTemplatePromise(accountId: string, type: string): Promise<firebase.functions.HttpsCallableResult> {
    const args: any = {
        action: "giftcard-template-read",
        account: accountId,
        type: type,
        template: "pdf"
    };

    const client = firebase.functions().httpsCallable("clientApi");
    return client(args);
}

export default withRoleRouterFunc(GiftcardPDFTemplateEditor)
