//
//  MarketEdit.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 04/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import { Alert, Button, Col, Form, FormControl, FormGroup, Card, ToggleButton, ToggleButtonGroup } from "../wrappers"
import { ref } from "../../config/constants"
import { Dictionary, cloneDeep } from "lodash"
import { Tax, Currency, Market } from "../../models/MarketModels"
import * as React from "react"
import LoadingButton, { PageState } from "../PageState"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { publish } from "../../helpers/ModelPublisher"
import { Globals } from "../../helpers/globals"
import { RoleRouterProps, withRoleRouter } from "src/routes"
import { Row } from "react-bootstrap"

interface MarketEditState {
    market: Market
    new: boolean
    identifier: string
    error: string | null
    taxes: Tax[]
    currencies: Currency[]
    dirty: boolean
    publishing: boolean
    loading: boolean
}

class MarketEdit extends React.Component<RoleRouterProps, MarketEditState> {

    // Lifecycle

    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            market: { id: "", name: "", taxes: new Set(), currency: "DKK" },
            new: true,
            identifier: "",
            error: null,
            taxes: [],
            currencies: [],
            dirty: false,
            loading: true,
            publishing: false
        }
    }

    // Component

    async componentDidMount() {
        await this.load()
    }

    render() {
        return (
            <PageState loading={this.state.loading} typeName="Market" publishing={this.state.publishing} dirty={this.state.dirty}>

                <Card className="my-4">
                    <Card.Header>{this.state.new ? `New market` : `Editing market: ${this.state.market.name}`}</Card.Header>
                    <Card.Body>

                        <Form onSubmit={e => e.preventDefault()}>

                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.market.name}
                                        placeholder="Enter name"
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <ValidatingIdEntryControl
                                collectionRef={this.marketsRef()}
                                isNew={this.state.new}
                                typeName="market"
                                identifierSource={this.state.market.name}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Currency</Col>
                                <Col sm={10}>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="currency"
                                        value={this.state.market.currency}
                                        onChange={this.handleCurrencyChange}
                                    >
                                        {
                                            this.state.currencies.map((currency) => {
                                                return <ToggleButton id={currency.currencyCode} key={currency.currencyCode} value={currency.currencyCode}>{currency.currencyCode}</ToggleButton>
                                            })
                                        }
                                    </ToggleButtonGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Default Taxes</Col>
                                <Col sm={10}>
                                    <ToggleButtonGroup
                                        type="checkbox"
                                        value={Array.from(this.state.market.taxes)}
                                        onChange={this.handleTaxesChange}
                                    >
                                        {
                                            this.state.taxes.map((tax) => {
                                                return <ToggleButton id={tax.id} key={tax.id} value={tax.id}>{tax.name}</ToggleButton>
                                            })
                                        }
                                    </ToggleButtonGroup>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                    <LoadingButton onClick={this.publish} disabled={!this.state.dirty}/>
                    </Card.Footer>
                </Card>
                {this.state.error ? (
                    <Alert variant="danger">
                        <strong>Error publishing market:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }

    // Methods

    handleCurrencyChange = (data: any) => {
        const market = this.state.market
        market.currency = data
        this.setState({ market: market, dirty: true, error: null })
    }

    handleTaxesChange = (data: any) => {
        const market = this.state.market
        market.taxes = new Set(data)
        this.setState({ market: market, dirty: true, error: null })
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value

        const market = cloneDeep(this.state.market)
        market.name = value || ""
        this.setState({ market: market, dirty: true, error: null })
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    marketsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("markets")
    }

    async load() {
        const promises: Promise<any>[] = [
            Globals.shared.getCurrencies(),
            Globals.shared.getTaxes()
        ]
        const marketKey = this.props.router.params.marketKey !== "new" ? this.props.router.params.marketKey : null

        let isNewMarket = true
        let market = this.state.market

        if (marketKey) {
            const markets = await Globals.shared.getMarkets()
            const candidate = markets.find(m => { return m.id === marketKey })
            if (candidate) {
                market = candidate
                isNewMarket = false
            }
        }

        const [currencies, taxes] = await Promise.all(promises)

        this.setState({ currencies: currencies, taxes: taxes, market: market, loading: false, new: isNewMarket, identifier: market.id })
    }

    publish = async () => {
        const market = this.state.market
        const taxes: Dictionary<boolean> = {}
        market.taxes.forEach(tax => {
            taxes[tax] = true
        })

        const marketJson = {
            name: market.name,
            currency: market.currency,
            taxes: taxes
        }

        this.setState({ publishing: true })

        try {
            await publish(marketJson, "id", this.state.identifier, this.state.new, this.marketsRef(), true)
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.props.router.navigate("/markets")
    }
}

export default withRoleRouter(MarketEdit)
