import React from "react";
import { Card } from "react-bootstrap";
import { Market } from "../../../../models/MarketModels";
import { FormType } from "../../../../models/RuleModels";
import { AppliesTo } from "../AppliesToSelector";
import { CustomerCondition } from "../CustomerConditionSelector";
import { DiscountType } from "../DiscountSelector";
import { RequirementsType } from "../MinimumRequirementsSelector";
import { RowData } from "../StairStep";
import Numeral from "numeral";
import { MarketAmount } from "../../../../models/MarketAmount";
import { LanguageCode } from "../../../../helpers/L10n";
import { CustomizableFormProps } from "./CustomizableForm";
import * as _ from "lodash"
import { dayjsFromDateComponents } from "../RuleTemplateForm";
import dayjs from "dayjs";

interface DescriptionProps {
    discountType: DiscountType;
    requirementsType: RequirementsType;
    steps: RowData[];
    formType: FormType;
    appliesTo: AppliesTo;
    formProps: CustomizableFormProps;
    customerCondition: CustomerCondition;
    market: Market | null;
    allShops: _.Dictionary<string>
    validation: boolean
}
export function Description(props: DescriptionProps) {
    return <Card className="my-4" border={props.validation ? "success" : "danger"} >
        <Card.Header>
            {props.formType === "discount" ? "Discount description" : "Point earning description"}
        </Card.Header>
        <Card.Body>
            {formattedDescription(props)}
        </Card.Body>
    </Card>;
}

function recieveOrEarn(formType: FormType): string {
    return formType === "discount" ? "recieve" : "earn"
}

function formattedDescription(props: DescriptionProps) {
    const marketId: string | null = props.market?.id ?? null;

    const tagNameForId = (id: string): string | null => {
        if (!props.formProps.tagObserver.tagsDict) {
            return id;
        }
        const tag = props.formProps.tagObserver.tagsDict[id];
        let result = id;
        if (tag) {
            result = tag.name.localized(LanguageCode.da);
        }
        return result;
    };
    const productNameForId = (id: string): string | null => {
        if (!props.formProps.productObserver.productsDict) {
            return null;
        }
        const product = props.formProps.productObserver.productsDict[id];
        let result: string | null = null;
        if (product) {
            result = product.localizedName(LanguageCode.da);
        }
        return result;
    };

    let matchString: string = "";
    if (props.appliesTo.type === "all") {
        matchString = "of any kind";
    } else if (props.appliesTo.type === "tags") {
        const list = props.appliesTo.tags.map(tagId => {
            return tagNameForId(tagId);
        });

        if (list.length === 1) {
            matchString = `that have the tag '${list[0]}'`;
        } else {
            matchString = `that have any of the tags '${list.join(", ")}'`;
        }

    } else if (props.appliesTo.type === "products") {

        const productNames = props.appliesTo.products.map(productId => {
            return productNameForId(productId) || productId;
        });
        if (productNames.length === 0) {
            matchString = "(please select one or more products)";
        }
        else if (productNames.length === 1) {
            matchString = `of type ${productNames[0]}`;
        } else {
            matchString = `in the list: [${productNames.join(", ")}]`;
        }
    } else if (props.appliesTo.type === "attributes") {
        const attributes = props.appliesTo.attributes;
        const attributeDescriptions = attributes.map((attributeSelection) => {
            const attribute = props.formProps.attributeObserver.attributesDict?.[attributeSelection.attributeId];
            if (attribute === undefined) {
                return "";
            }
            let value = attributeSelection.optionId
            const option = attribute.type.options?.[attributeSelection.optionId];
            if (option !== undefined) {
                value = option.name.localized(LanguageCode.da)
            }

            return `${attribute.name.localized(LanguageCode.da)}: ${value}`;
        });
        if (attributeDescriptions.length === 1) {
            matchString = `with the attribute value: ${attributeDescriptions[0]}`;
        } else {
            matchString = `with any of the attribute values in the list: [${attributeDescriptions.join(", ")}]`;
        }
    }

    let conditionString: string = `Items ${matchString} ` + recieveOrEarn(props.formType);
    if (props.steps.length === 1) {
        const step = props.steps[0];
        if (props.requirementsType === "count") {
            const count = step.count ?? 0;
            conditionString = `When there are ${count} or more items ${matchString}, they ` + recieveOrEarn(props.formType);
        } else if (props.requirementsType === "amount") {
            const amount = step.amount ?? new MarketAmount(0);
            conditionString = `When basket total is ${Numeral(amount.amount(marketId)).format("0,0.00")} or more, items ${matchString} ` + recieveOrEarn(props.formType);
        } else if (props.requirementsType === "none") {
            /* */
        }
    }

    let discountString = "";
    if (props.steps.length === 1) {
        const step = props.steps[0];
        if (props.discountType === "percentage") {
            const value = step.discountPercentage ?? 0;
            const percentage = Numeral(value).multiply(100).format("0.0");
            if (props.formType === "discount") {
                discountString = `a discount of ${percentage} percent.`;
            } else {
                discountString = `${percentage} percent in points.`;
            }

        } else if (props.discountType === "amount_off") {
            const value = step.discountAmount ?? new MarketAmount(0);
            const formatted = Numeral(value.amount(marketId)).format("0,0.00");
            if (props.formType === "discount") {
                discountString = `a discount of ${formatted}.`;
            } else {
                discountString = `${formatted} points.`;
            }
        } else if (props.discountType === "new_price") {
            const value = step.discountAmount ?? new MarketAmount(0);
            const formatted = Numeral(value.amount(marketId)).format("0,0.00");
            discountString = `a new price of ${formatted}.`;
        }
    } else {
        let basedOn = "";
        if (props.requirementsType === "none") {
            /* */
        } else if (props.requirementsType === "amount") {
            basedOn = "based on basket total";
        } else if (props.requirementsType === "count") {
            basedOn = "based on the count of items";
        }
        if (props.discountType === "percentage") {
            if (props.formType === "discount") {
                discountString = `a percentage discount ${basedOn}.`;
            } else {
                discountString = `a percentage of points ${basedOn}.`;
            }

        } else if (props.discountType === "amount_off") {
            if (props.formType === "discount") {
                discountString = `a discount ${basedOn}.`;
            } else {
                discountString = `points ${basedOn}.`;
            }

        } else if (props.discountType === "new_price") {
            discountString = `a new price ${basedOn}.`;
        }
    }
    let customerString = "";
    if (props.customerCondition.type === "all") {
        // No description when it applies on all sales
    } else if (props.customerCondition.type === "members") {
        customerString = "Only applies when a customer is added to the sale.";
    } else if (props.customerCondition.type === "customer_selection") {
        customerString = `Only applies for the following customers: ${props.customerCondition.customers.join(", ")}`;
    } else if (props.customerCondition.type === "attributes") {
        const attributes = props.customerCondition.attributes;
        const attributeDescriptions = attributes.map((attributeSelection) => {
            const attribute = props.formProps.customerAttributeObserver.attributesDict?.[attributeSelection.attributeId];
            if (attribute === undefined) {
                return "";
            }
            const option = attribute.type.options?.[attributeSelection.optionId];
            if (option === undefined) {
                return "";
            }

            return `${attribute.name.localized(LanguageCode.da)}: ${option.name.localized(LanguageCode.da)}`;
        });
        if (attributeDescriptions.length === 1) {
            customerString = `Applies to customers with the attribute value: ${attributeDescriptions[0]}`;
        } else {
            customerString = `Applies to customers with any of the attribute values in the list: [${attributeDescriptions.join(", ")}]`;
        }
    }
    const mainDescription = [conditionString, discountString].join(" ")
    const descriptionStrings: string[] = [mainDescription, customerString]

    if (!_.isNil(props.formProps.template.start_date) && !_.isNil(props.formProps.template.end_date)) {
        const from = dayjsFromDateComponents(props.formProps.template.start_date).format("MMMM Do, YYYY")
        const to = dayjsFromDateComponents(props.formProps.template.end_date).format("MMMM Do, YYYY")
        descriptionStrings.push(`The rule runs from ${from} to ${to} - including both dates`)
    } else if (!_.isNil(props.formProps.template.start_date)) {
        const from = dayjsFromDateComponents(props.formProps.template.start_date).format("MMMM Do, YYYY")
        descriptionStrings.push(`The rule runs from ${from} and continues to run until it is deleted`)
    } else if (!_.isNil(props.formProps.template.end_date)) {
        const to = dayjsFromDateComponents(props.formProps.template.end_date).format("MMMM Do, YYYY")
        descriptionStrings.push(`The rule runs to the end of ${to}`)
    } else {
        descriptionStrings.push(`The rule has no time limits and runs until it is deleted.`)
    }

    const today = dayjs().startOf("day").format("YYYY-MM-DD")
    let expired = false
    let upcoming = false
    if (!_.isNil(props.formProps.template.end_date)) {
        const endDate = dayjsFromDateComponents(props.formProps.template.end_date).format("YYYY-MM-DD")
        if (today > endDate) {
            expired = true
        }
    }
    if (!_.isNil(props.formProps.template.start_date)) {
        const startDate = dayjsFromDateComponents(props.formProps.template.start_date).format("YYYY-MM-DD")
        if (today < startDate) {
            upcoming = true
        }
    }

    const lines = descriptionStrings.map((value, index) => { return <p key={index}>{value}</p> })
    if (upcoming) {
        lines.push(<p key="a"><b>NOTE: This discount is not yet active!</b></p>)
    }
    if (expired) {
        lines.push(<p key="b"><b>NOTE: This discount has expired!</b></p>)
    }
    const continueEvaluation = props.formProps.template.continue_evaluation || false
    if (continueEvaluation) {
        lines.push(<p key="c"><b>NOTE: After this discount has applied, the line items receiving discounts are legible for other discounts.</b></p>)
    }
    const shops = props.formProps.template.shop_ids ?? []
    if (shops.length > 0) {
        lines.push(<p key="d"><b>NOTE: This discount is only applicable for the shops: {shops.map(shop => { return props.allShops[shop] ?? shop}).join(", ")}</b></p>)
    }

    return lines
}
