import React, { useState, useEffect } from "react"
import { Button, DropdownButton, FormControl, FormGroup, InputGroup, Dropdown, Card, FormCheck, Form } from "react-bootstrap"
import { LanguageCode } from "../../../helpers/L10n"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { Attribute } from "../../../models/Product"
import { StripedTable } from "../../../components/StripedTable"
import * as _ from "lodash"
import { SelectAttributeOption, SelectAttributeText } from "./AppliesToSelector"

interface CustomerElementProps {
    customer: string
    remove: (key: string) => void
}

function CustomerElement(props: CustomerElementProps) {
    return <tr><td>{props.customer}<Button className="float-sm-end" variant="link" onClick={() => { props.remove(props.customer) }}><DeleteButton /></Button></td></tr>
}

export function DeleteButton() {
    return <span role="img" aria-label="delete button">✖️</span>
}

interface AttributeElementProps {
    attribute?: Attribute
    option: string
    remove: (key: string) => void
}
function AttributeElement(props: AttributeElementProps) {
    if (!props.attribute) {
        return <span />
    }
    const option = (props.attribute!.type.options ?? {})[props.option]
    return <tr><td>{props.attribute.name.localized(LanguageCode.da)}: {option.name.localized(LanguageCode.da)}<Button style={{ float: "right" }} variant="link" onClick={() => { props.remove(props.attribute!.id) }}><DeleteButton /></Button></td></tr>
}

interface CustomerConditionSelectorProps {
    condition: CustomerCondition
    conditionChanged: (condition: CustomerCondition) => void
    attributesObserver: AttributeObserver
    validation: boolean
}

interface AttributeSelection {
    attributeId: string
    optionId: string
}

export interface CustomerCondition {
    type: CustomerConditionType
    customers: string[]
    attributes: AttributeSelection[]
}

export type CustomerConditionType = "all" | "members" | "customer_selection" | "attributes"

export function CustomerConditionSelector(props: CustomerConditionSelectorProps) {
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>(props.condition.customers)
    const [selectedAttributes, setSelectedAttributes] = useState<AttributeSelection[]>(props.condition.attributes)
    const [selectAttributeOption, setSelectAttributeOption] = useState<string | undefined>(undefined)
    const [customerId, setCustomerId] = useState<string>("")
    const [type, setType] = useState<CustomerConditionType>(props.condition.type);

    const addCustomerId = () => {
        const clone = [...selectedCustomers]
        clone.push(customerId)
        setSelectedCustomers(clone)
        setCustomerId("")
    }

    useEffect(() => {
        const existing = props.condition
        const updated: CustomerCondition = {
            type: type,
            customers: selectedCustomers,
            attributes: selectedAttributes
        }
        if (_.isEqual(existing, updated)) {
            return
        }
        props.conditionChanged(updated)
    }, [type, selectedAttributes, selectedCustomers, props])

    return <Card className="my-4" border="primary" >
        <Card.Header>
            Customer conditions
        </Card.Header>
        <Card.Body>
            <Form validated={props.validation}>
                <Form.Group
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                >
                    <FormCheck
                        type="radio"
                        id="customerConditionSelector"
                        label="All sales"
                        checked={type === "all"}
                        onChange={() => { setType("all") }}
                    >
                    </FormCheck>
                    <FormCheck
                        type="radio"
                        id="customerConditionSelector"
                        label="Members only"
                        checked={type === "members"}
                        onChange={() => { setType("members") }}
                    />
                    <FormCheck
                        type="radio"
                        label="Specific customers"
                        id="customerConditionSelector"
                        checked={type === "customer_selection"}
                        onChange={() => { setType("customer_selection") }}
                    />
                    <FormCheck
                        type="radio"
                        id="customerConditionSelector"
                        label="Customers with specific attribute values"
                        checked={type === "attributes"}
                        onChange={() => { setType("attributes") }}
                    />
                    {type !== "all" && type !== "members" && <hr />}
                    {type === "customer_selection" &&
                        <div>
                            {selectedCustomers.length > 0 &&
                                <StripedTable>
                                    <thead><tr><td colSpan={2}>Apply discount for any of the customers below</td></tr></thead>
                                    <tbody>
                                        {selectedCustomers.map(key => {
                                            return <CustomerElement key={key} remove={key => {
                                                const clone = selectedCustomers.filter(element => { return element !== key })
                                                setSelectedCustomers(clone)
                                            }} customer={key} />
                                        })}
                                    </tbody>
                                </StripedTable>
                            }

                            <InputGroup>
                                <Button onClick={() => {
                                    addCustomerId()
                                }}>Add</Button>

                                <FormControl
                                    // onBlur={() => { this.onBlur() }}
                                    // onFocus={() => { this.setState({ focus: true }) }}
                                    type="text"
                                    value={customerId}
                                    placeholder="Enter customer identifier"
                                    onChange={e => { setCustomerId((e.target as any).value as string) }}
                                    onKeyPress={e => {
                                        if (e.key === "Enter") {
                                            addCustomerId()
                                        }
                                    }}
                                    autoComplete="off"
                                />
                            </InputGroup>
                        </div>
                    }

                    {type === "attributes" &&
                        <div>
                            {selectedAttributes.length > 0 &&
                                <StripedTable>
                                    <thead><tr><td>Apply discount if the product has the specified value for any of the attributes below</td></tr></thead>
                                    <tbody>
                                        {selectedAttributes.map(key => {
                                            return <AttributeElement key={`${key.attributeId}-${key.optionId}`} remove={key => {
                                                const clone = selectedAttributes.filter(element => { return element.attributeId !== key })
                                                setSelectedAttributes(clone)
                                            }} attribute={(props.attributesObserver.attributesDict ?? {})[key.attributeId]} option={key.optionId} />
                                        })}
                                    </tbody>
                                </StripedTable>
                            }
                            {selectAttributeOption !== undefined ? (
                                (props.attributesObserver.attributesDict ?? {})[selectAttributeOption].type.options !== undefined ?
                                    <SelectAttributeOption attribute={(props.attributesObserver.attributesDict ?? {})[selectAttributeOption]} optionSelected={(attributeId, optionId) => {
                                        const clone = [...selectedAttributes]
                                        clone.push({ attributeId: attributeId, optionId: optionId })
                                        setSelectedAttributes(clone)
                                        setSelectAttributeOption(undefined)
                                    }} />
                                    :
                                    <SelectAttributeText attribute={(props.attributesObserver.attributesDict ?? {})[selectAttributeOption]} textSelected={(attributeId, optionId) => {
                                        const clone = [...selectedAttributes]
                                        clone.push({ attributeId: attributeId, optionId: optionId })
                                        setSelectedAttributes(clone)
                                        setSelectAttributeOption(undefined)
                                    }} />
                            ) :
                                <div>
                                    <DropdownButton title="Select one or more attributes" id="a">
                                        {(props.attributesObserver.attributesArray ?? []).map(attribute => {
                                            if (attribute.type.options === undefined && attribute.type.text === undefined) {
                                                return undefined
                                            }

                                            return <Dropdown.Item key={attribute.id} onClick={() => {
                                                setSelectAttributeOption(attribute.id)
                                            }} eventKey={attribute.id}>{`${attribute.name.localized(LanguageCode.da)}`}</Dropdown.Item>
                                        })}
                                    </DropdownButton>
                                </div>
                            }
                        </div>
                    }
                </Form.Group>
            </Form>
        </Card.Body>
    </Card >
}

