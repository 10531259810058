import * as React from "react"
import { RoleRouterProps, withRoleRouterFunc } from "../../../routes"
import { PageState } from "../../PageState"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { StockCountOpenModal } from "../StockCountList/StockCountOpenModal"
import { firestore, ref } from "../../../config/constants"
import { v4 as uuidv4 } from "uuid"
import _ from "lodash"
import * as Auth from "../../../helpers/auth"
import { StockCountFilter } from "../../../models/StockCountFilter"
import { StockCountRequest, stockCountRequestToJSON } from "../../../models/StockCountRequest"
import { StockCountRequestList } from "./StockCountRequestList"

function requestCollectionRef(accountId: string) {
    return firestore.collection(`accounts/${accountId}/stock_count_requests`)
}

function StockCountRequests(props: RoleRouterProps) {
    const [showModal, setShowModal] = useState(false)
    async function createStockCountRequest(name: string, filter: StockCountFilter, shops: Set<string>, dueDate: Date) {
        setShowModal(false)

        const uid = Auth.userId() ?? "_"

        const userPath = `v1/accounts/${props.role.account_id}/users/${uid}`
        const userSnapshot = await ref().child(userPath).once("value")
        const userJson = userSnapshot.val() ?? {}
        const email = userJson.email ?? Auth.currentUser()?.email ?? ""

        const request: StockCountRequest = {
            name: name,
            filter: filter,
            shops: Array.from(shops),
            dueDate: dueDate,
            id: uuidv4(),
            creationDate: new Date(),
            createdBy: email,
            status: "created"
        }
        const requestJSON = stockCountRequestToJSON(request)
        const collectionRef = requestCollectionRef(props.role.account_id)
        const documentReference = await collectionRef.add(requestJSON)

        // For each shop, create a request (in Firestore) under the stock location
        // For each shop, create a 'status' document under 'documentReference' that is updated when they complete the count

        for (const shop of shops) {
            const stockLocationRef = firestore.collection(`accounts/${props.role.account_id}/stock_locations/${shop}/stock_count_requests`)
            await stockLocationRef.add(requestJSON)
            const statusRef = documentReference.collection("status").doc(shop)
            await statusRef.set({
                status: "created"
            })
        }
    }

    return <PageState typeName="stock count request" dirty={false} loading={false} publishing={false} submit_action={async () => { }}>
        <Button onClick={() => { setShowModal(true) }}>Request a stock count</Button>
        {
            showModal ? (
                <StockCountOpenModal
                    mode="stock_count_request"
                    stockLocation={""}
                    role={props.role}
                    openNew={async (name, filter, shops, dueDate) => { await createStockCountRequest(name, filter, shops, dueDate) }}
                    cancel={() => { setShowModal(false) }}
                />
            ) : null
        }
        <StockCountRequestList accountId={props.role.account_id} />

    </PageState>
}


export default withRoleRouterFunc(StockCountRequests)